<template>
  <div
    class="popbox"
    @mouseover="debounce"
    @click="emit"
    :class="[{ disabled }, {popLeft}, { popRight}]"
    @mouseleave="debounceClose"
  >
    <div class="popitem">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tips: {
      type: String,
      default: "提示"
    },
    accessId: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    popLeft: {
      type: Boolean,
      default: false
    },
    popRight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      close: false
    };
  },
  methods: {
    debounce(e) {
      if (this.show) return false;
      this.mouseover(e);
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 200);
    },
    debounceClose() {
      if (this.close) return false;
      this.mouseleave();
      this.close = true;
      setTimeout(() => {
        this.close = false;
      }, 200);
    },
    mouseover(e) {
      this.show = false;
      let isexit = document.getElementById("pop");
      if (isexit) return false;
      let div = document.createElement("div");
      div.innerText = this.tips;
      div.id = "pop";
      div.className = "pop";
      div.style.top = `${e.target.getBoundingClientRect().top - 8}px`;
      div.style.left = `${e.target.getBoundingClientRect().left}px`;
      // div.style.top = `${e.offsetY}px`;
      // div.style.left = `${e.offsetX}px`;
      div.style.transform = "translate(0,-100%)";
      div.style.zIndex = "999999";
      document.body.appendChild(div);
    },
    mouseleave() {
      let isexit = document.getElementById("pop");
      if (isexit) {
        document.body.removeChild(isexit);
      }
    },
    emit() {
      let isexit = document.getElementById("pop");
      if (isexit) {
        document.body.removeChild(isexit);
      }
      if (this.disabled) return false;
      //  this.$emit("myclick");
      if (this.accessId) {
        let superAdmin = JSON.parse(window.localStorage.getItem("userInfo"));
        if (superAdmin.is_manage == 1) {
          this.$emit("myclick");
          this.$emit("click");

        } else {
        if (this.$common.hasRole(this.accessId)) {
            this.$emit("myclick");
          this.$emit("click");

          } else {
            this.$message({ message: "暂无权限!", type: "error" });
          }
        }
      } else {
        this.$emit("myclick");
          this.$emit("click");

      }
    }
  }
};
</script>

<style lang="scss">
.popbox {
  padding: 0;
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  display: inline-block;
  margin: 0;
  /* margin: 0 auto; */
  .icon {
    width: 14px;
    height: 14px;
  }
}
.popRight {
  margin-right: 15px;
}
.popLeft {
  margin-left: 15px;
}
.popitem {
  padding: 0;
  display: flex;
  align-items: center;
}
.pop {
  min-height: 25px;
  // max-width: 100px;
  word-break: break-all;
  position: absolute;
  top: 80px;
  left: 100px;
  background: #3d7eff;
  text-align: center;
  line-height: 25px;
  color: #ffffff;
  font-size: 10px;
  padding: 0 5px;
  pointer-events: none;
  border-radius: 2px;
}
.pop::after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 0px solid transparent;
  border-top: 7px solid #3d7eff;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  bottom: -5px;
}
.disabled {
  cursor: not-allowed;
}
</style>
