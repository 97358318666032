// import { Close, ArrowDown } from '@element-plus/icons-vue';
import Vue from "vue";
import "./style/style.scss";
import confirmbox from "./components/confirm.vue";
import myInput from "./components/my-input";
import navBar from "./components/navBar";
import myselect from "./components/myselect";
import myButton from "./components/myButton";
import pop from "./components/pop";
import navlid from "./components/nvalid";
import * as request from "../plugin/request";
import * as common from "../plugin/common";
import { Message } from "element-ui";
import saveFile from "./downLoad";
import router from "../router/index";
const message = (msg, type = "error") => {
  return Message({
    message: msg,
    type,
  });
};
// 导出
function exported(url, params, filename = "download.xlsx") {
  if (!url) return false;
  request
    .HttpPost(url, params, { responseType: "blob" })
    .then((res) => {
      let blob = new Blob([res]);
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    })
    .catch((err) => {
      console.log("导出失败", err);
    });
}

// 上传
function upload(file, url, data, value) {
  //data需要改变的原对象,value要改变的键值对
  let obj = new FormData();
  obj.append("file", file.raw);
  request.HttpPost(url, obj).then((res) => {
    if (res.data) {
      if (data) {
        data[value] = res.data.fileInfo.fileUrl;
      }
    }
  });
}

// 确认框
const confirm = function (
  tips = "提示内容",
  title = "提示",
  type,
  confirmText = "确认",
  cancelText = "取消"
) {
  const dom = Vue.extend(confirmbox);
  const confirmDom = new dom();
  confirmDom.$mount();
  document.body.appendChild(confirmDom.$el);
  confirmDom.show = true;
  confirmDom.tips = tips;
  confirmDom.title = title;
  confirmDom.type = type ? type : "confirm";
  confirmDom.cancelText = cancelText;
  confirmDom.confirmText = confirmText;
  setTimeout(() => {
    confirmDom.boxshow = true;
  }, 10);
  return confirmDom
    .isconfirm()
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally((e) => {
      document.body.removeChild(confirmDom.$el);
    });
};

// 回车搜索
function keyDownSearch(e, fn) {
  if (e.key == "Enter" || e.keyCode == 13) {
    fn();
  }
}

// // 计算加减
// function calcNum() {
//   let arr = [...arguments];
//   // 最长
//   let maxNum = arr.reduce((max, item) => {
//     let value = item? item.toString():'';
//     let index = value.indexOf('.');
//     if (index > -1) {
//       let length = value.substring(index, value.length).length
//       return max = max > length ? max : length
//     } else {
//       return max
//     }
//   }, 0);

//   // 乘数
//   let cx = '1'
//   for (let i = 0; i < maxNum; i++) {
//     cx += 0;
//   }
//   cx = cx * 1;
//   // 结果
//   let value = arr.reduce((total, item) => {
//     let reg = /^\d\d*\.?\d*$/;
//    let  values = reg.test(item) ? item:0;
//     return total += values * cx
//   }, 0)
//   return value / cx
// }

// 图片放大查看
function preImage(src) {
  let box = document.createElement("div");
  box.className = "preview-box";
  let image = new Image();
  image.src = src;
  image.className = "previewImage";
  image.onerror = function () {
    image.src = require("./img/picfail.png");
  };
  /*
  滚动
  */
  let scale = 1;
  if (window.addEventListener)
    //FF,火狐浏览器会识别该方法
    window.addEventListener("DOMMouseScroll", wheel, false);
  window.onmousewheel = document.onmousewheel = wheel; //W3C
  //统一处理滚轮滚动事件
  function wheel(event) {
    var delta = 0;
    if (!event) event = window.event;
    if (event.wheelDelta) {
      //IE、chrome浏览器使用的是wheelDelta，并且值为“正负120”
      delta = event.wheelDelta / 120;
      if (window.opera) delta = -delta; //因为IE、chrome等向下滚动是负值，FF是正值，为了处理一致性，在此取反处理
    } else if (event.detail) {
      //FF浏览器使用的是detail,其值为“正负3”
      delta = -event.detail / 3;
    }
    if (delta) handle(delta);
  }
  //上下滚动时的具体处理函数
  function handle(delta) {
    if (delta < 0) {
      //向下滚动
      if (scale <= 0.5) {
        return false;
      }
      scale -= 0.2;
      image.style.transform = `translate(-50%,-50%) scale(${scale})`;
    } else {
      //向上滚动
      if (scale >= 3) {
        return false;
      }
      scale += 0.2;
      image.style.transform = `translate(-50%,-50%) scale(${scale})`;
    }
  }

  /*
  结束
  */

  setTimeout(() => {
    image.classList.add("fade");
  }, 10);
  box.appendChild(image);
  document.body.appendChild(box);
  box.onclick = () => {
    document.body.removeChild(box);
  };
}
function timeChange(e, data, startTime, endTime) {
  // e,时间数组，data需要改变的对象
  if (e) {
    data[startTime] = e[0];
    data[endTime] = e[1];
  } else {
    data[startTime] = "";
    data[endTime] = "";
  }
}
// 验证规则
function setRules(
  message = "请输入",
  type = "blur",
  required = true,
  othrules
) {
  let rules = [{ required, message: message, trigger: type }];
  if (othrules) {
    rules = [...rules, ...othrules];
  }
  return rules;
}

//  筛选
async function querySearch(query, cb, fn, key) {
  let data = await fn();
  cb([]);
  if (!query) return cb(data);
  if (!data || !Array.isArray(data)) return cb([]);
  return cb(
    data.filter((item) => {
      return item[key].indexOf(query) !== -1;
    })
  );
}

// router blank
function openNewTab(url) {
  let { href } = router.resolve({ path: url });
  window.open(href);
}
export default {
  install(Vue) {
    Vue.component("MyInput", myInput);
    Vue.component("MyButton", myButton);
    Vue.component("myselect", myselect);
    Vue.component("NavBar", navBar);
    Vue.prototype.$myconfirm = confirm;
    Vue.prototype.$rmessage = message;
    Vue.prototype.$request = request;
    Vue.prototype.$common = common;
    Vue.prototype.$exported = exported;
    Vue.prototype.$upload = upload;
    Vue.prototype.$keyDownSearch = keyDownSearch;
    Vue.prototype.$saveFile = saveFile;
    Vue.prototype.$preImage = preImage; //预览图片调用方法
    Vue.prototype.$timeChange = timeChange;
    Vue.prototype.$setRules = setRules;
    Vue.prototype.$querySearch = querySearch;
    Vue.prototype.$openNewTab = openNewTab;
    Vue.component("Pop", pop);
    Vue.component("Navlid", navlid);
    // 图片错误替换
    Vue.directive("img", {
      inserted(el) {
        if (!el.src) return (el.src = require("./img/picfail.png"));
        el.onerror = function () {
          el.style.objectFit = "contain";
          el.src = require("./img/picfail.png");
        };
        el.onload = function () {};
      },
      //更新时候
      update(el) {
        if (!el.src) return (el.src = require("./img/picfail.png"));
        el.onerror = function () {
          el.style.objectFit = "contain";
          el.src = require("./img/picfail.png");
        };
        el.onload = function () {};
      },
    });

    // 预览
    Vue.directive("preview", {
      inserted(el, { value }) {
        let src = value ? value : el.src; //，可以传值  v-preview = 'src'
        el.style.cursor = "pointer";
        el.onclick = () => {
          preImage(src);
        };
      },
      update(el, { value }) {
        let src = value ? value : el.src;
        let elImg = el.querySelector("img");
        if (!src && elImg) {
          src = elImg.src;
        }
        el.onclick = () => {
          preImage(src);
        };
      },
    });
    Vue.directive("num", {
      inserted(el, bind) {
        let maxValue = bind.value == 0 ? 0 : bind.value ? bind.value : 0; //小数位数
        let inputEl = el.querySelector("input") || el;
        inputEl.addEventListener("change", function () {
          let value = this.value;
          if (!value) return;
          let regrule =
            maxValue == 0 ? "\\d+" : `\\d+(\\.?\\d{1,${maxValue}}|\\d*)`;
          let reg = new RegExp(regrule, "g");
          let mathcValue = value.match(reg);
          let setValue = mathcValue ? mathcValue[0] : 0;
          this.value = setValue;
          this.dispatchEvent(new Event("input"));
          this.dispatchEvent(new Event("blur"));
        });
      },
    });
  },
};
