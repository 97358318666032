<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  created() {
    let token =
      this.getQueryVariable("token") || window.localStorage.getItem("token");
    let exp =
      this.getQueryVariable("exp") || window.localStorage.getItem("exp");
    if (token) {
      window.localStorage.setItem("token", token);
      // this.getuserInfo();
    }
    if (exp) {
      window.localStorage.setItem("exp", exp * 1000);
    }
  },
  data() {
    return {};
  },
  methods: {
    getQueryVariable(val) {
      const w = window.location.search.indexOf("?");
      const query = window.location.search.substring(w + 1);
      const vars = query.split("&");
      for (let i = 0; i <vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == val) {
          return pair[1];
        }
      }
      return null;
    },
    getuserInfo() {
      // this.$request.HttpPost("/refresh").then((res) => {
      //   if (res.data) {
      //     // let loginInfo = JSON.stringify(res.data);
      //     window.localStorage.setItem("exp", res.data.claims.exp * 1000);
      //     window.localStorage.setItem("token", res.data.token);
      //     if (res.data.role_id) {
      //       this.$store.commit("setRoleIds", res.data.role_id.split(","));
      //     }
      //     // window.localStorage.setItem('loginInfo', loginInfo);
      //   }
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  background-color: #ffffff;
  //min-width: 1200px;
}
/deep/ .el-dialog {
  .el-dialog__header {
    border-bottom: 1px solid #f5f5f5;
  }
  .el-dialog__title {
    font-size: 14px;
  }
}
</style>
