import { dailyGet, dailyPost, HttpGet } from "@/plugin/request";

/**
 *
 * @param {*} url 请求地址
 * @param {*} params 参数
 * @returns  列表地址
 */
const getDataList = (url, params) => {
  return dailyGet(url, params);
};
/**
 *
 * @param {*} params
 * @returns 新增水电设备
 */
const addMeter = (params) => {
  return dailyPost("/meterItem/add", params);
};

/**
 * 修改水电
 * @param {参数} params 
 */
const modifyMeter = (params = {}) => {
  return dailyPost("/meterItem/edit", params);
};

/**
 * 删除水电
 * @param {参数} params 
 * @returns 
 */
const delMeter = (params = {}) => {
  return dailyGet('/meterItem/delete', params);
}

/**
 *
 * @returns 水电清零
 */
const editeMeter = () => {
  return dailyPost("/meterItem/edite", params);
};

/**
 *
 * @returns 商铺列表
 */
const getStallList = (param) => {
  return HttpGet("/contract/stallList", {
    ...param,
    pageNum: 1,
    pageSize: 9999,
  });
};
/**
 *
 * @param {*} meter_number 水电表编号
 * @returns 详情数据
 */
const getMeterInfo = (meter_number) => {
  return dailyGet("/dh_daily/getWithMerchants", {
    meter_number,
  });
};
/**
 *
 * @param {*} params
 * @returns 充值
 */
const addMeterPrice = (params) => {
  return dailyPost("/meterItemPay/add", params);
};
/**
 * @param {*} pay_no 单号
 * 查询支付状态
 */
const checkRechargeStatus = (pay_no) => {
  return dailyGet("/meterItemPay/getPayNo", {
    pay_no,
  });
};

const getPrint = (id) => {
  return dailyGet("/meterItemPay/updatePrintCount", {
    id,
  });
};

const makeConfirm = (data) => {
  return dailyPost("/meterInvoicingRecord/add", data);
}

const getMake = (id) => {
  return dailyPost("/meterInvoicingRecord/get", {
    id
  });
}
export {
  getDataList,
  addMeter,
  editeMeter,
  getStallList,
  getMeterInfo,
  addMeterPrice,
  checkRechargeStatus,
  modifyMeter,
  delMeter,
  getPrint,
  makeConfirm,
  getMake
};
