let list = [];
let listRoot = [
  [
    {
      name: "物业管理",
      routeUrl: "",
      child: [
        {
          name: "操作台",
          url: "/opercenter",
          icon: "el-icon-s-home",
          id: 11754,
        },
        {
          name: "市场概览",
          url: "/datapreview",
          icon: "el-icon-s-marketing",
          id: 45820,
        },

        {
          name: "商户管理",
          url: "/merchants",
          icon: "el-icon-s-custom",
          id: 41011,
        },
        // 合同------------------------------------------------------------------------
        {
          name: "合同管理",
          url: "/contract",
          id: 71477,
          icon: "el-icon-s-order",
          child: [
            {
              name: "合同概览",
              url: "/contract/contractpreview",
              id: 80035,
            },
            {
              name: "新增合同",
              url: "/contract/addedcontract",
              id: 92169,
            },
            {
              name: "合同列表",
              url: "/contract/contractlist",
              id: 82936,
            },
            // {
            //   name: "附属合同列表",
            //   url: "/contract/attachlist",
            //   id: 51000,
            // },
            {
              name: "批量合同导入",
              url: "/contract/batchcontract",
              id: 82937,
            },
            {
              name: "历史合同",
              url: "/contract/contracthistory",
              id: 81957,
            },
            {
              name: "合同设置",
              url: "/contract/contractSetting/contractsSetting",
              id: 68572,
            },
          ],
        },
        // {
        //   name:"临时租赁",
        //   url:"/temp",
        // },
        // 商铺--------------------------------------------------------------------------------------------
        {
          name: "商铺管理",
          url: "/merchant",
          id: 88428,
          icon: "el-icon-s-shop",
          child: [
            {
              name: "商铺出租概览",
              url: "/merchant/merchantpreview",
              id: 35398,
            },
            {
              name: "商铺列表",
              url: "/merchant/merchantlist",
              id: 43269,
            },
            {
              name: "商铺类型",
              url: "/merchant/merchanttype",
              id: 69689,
            },
            {
              name: "商铺设置",
              url: "/merchant/merchantsetting",
              id: 13776,
            },
            {
              name: "楼栋管理",
              url: "/merchant/building",
              id: 69690,
            },
          ],
        },
        // 收费--------------------------------
        {
          name: "收费管理",
          url: "/charge",
          id: 681,
          icon: "el-icon-s-finance",
          child: [
            {
              name: "收费概览",
              url: "/charge/chargepreview",
              id: 11531,
            },
            {
              name: "商户账单",
              url: "/charge/merchantbill/basebill",
              id: 93115,
            },

            {
              name: "转移记录",
              url: "/charge/transfer",
              id: 11533,
            },
            {
              name: "收费记录",
              url: "/charge/chargeoffline",
              id: 51706,
            },

            {
              name: "意向金",
              url: "/charge/intention",
              id: 16542,
            },
            {
              name: "收费设置",
              url: "/charge/chargesetting/chargeset",
              id: 16155,
            },
            {
              name: "收费凭证",
              url: "/charge/chargevoucher",
              id: 34523,
            },
          ],
        },
        {
          name: "投诉管理",
          url: "/complain",
          icon: "el-icon-warning",
          id: 84719,
          child: [
            {
              name: "投诉概览",
              url: "/complain/complainpreview",
              id: 44428,
            },
            {
              name: "详细列表",
              url: "/complain/complainlist/pending",
              id: 81147,
              othChild: [
                {
                  name: "待处理",
                  url: "/complain/complainlist/pending",
                },
                {
                  name: "已处理",
                  url: "/complain/complainlist/processed",
                },
              ],
            },
          ],
        },
        {
          name: "智能巡检",
          url: "/polling",
          id: 2423,
          icon: "el-icon-s-claim",
          child: [
            {
              name: "巡检监管",
              url: "/polling/pollingsupervise",
              id: 84720,
            },
            {
              name: "巡检计划表",
              url: "/polling/pollingschedule",
              id: 84722,
            },
            {
              name: "巡检情况",
              url: "/polling/abnormal",
              id: 847226,
            },
            {
              name: "巡检日志",
              url: "/polling/pollinglog",
              id: 8472230,
            },
            {
              name: "巡检设置",
              url: "/polling/pollingsetting",
              id: 847250,
            },
          ],
        },
        {
          name: "报修管理",
          url: "/repair",
          icon: "el-icon-s-release",
          id: 2424,
          child: [
            {
              name: "报修列表",
              url: "/repair/list",
              id: 2425,
            },
            {
              name: "维修记录",
              url: "/repair/record",
              id: 2426,
            },
          ],
        },
        {
          name: "财务管理",
          url: "/finance",
          id: 75788,
          icon: "el-icon-s-data",
          child: [
            {
              name: "财务概览",
              url: "/finance/financepreview",
              id: 55053,
            },
            // {
            //   url: "/finance/dataReport",
            //   name: "数据报表",
            //   jurisdictionId: "",
            //   id: 55054,
            // },
            {
              url: '/finance/financialStatement/merchantList',
              name: '财务报表',
              jurisdictionId: "",
              id: 55055,
            },
            {
              name: "对账管理",
              url: "/finance/accountcheck/lineBill",
              id: 61182,
            },
            {
              name: "退款管理",
              url: "/finance/refund",
              id: 85139,
            },
          ],
        },

        {
          name: "设备管理",
          url: "/devMan",
          id: 93800,
          icon: "el-icon-s-platform",
        },
        {
          name: "操作日志",
          url: "/operlog",
          icon: "el-icon-s-grid",
          id: 93664,
        },
        {
          name: "操作员设置",
          url: "/opermanSetup/manage",
          icon: "el-icon-user-solid",
          id: 73416,
        },
        {
          name: "系统设置",
          url: "/system/account",
          icon: "el-icon-s-tools",
          id: 40437,
        },
      ],
    },
  ],
];

list = listRoot[0][0].child;
process.env.VUE_APP_HAS_METER == "-1" &&
  list.push(
    //电器仪器管理
    {
      name: "智能水电管理",
      url: "/meter",
      id: 75788,
      child: [
        {
          name: "智能电表管理",
          url: "/meter/meterManage",
          id: 55053,
        },
        {
          name: "智能水表管理",
          url: "/meterWater/meterManage",
          id: 55053,
        },
      ],
    }
  );
export default list;
