import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import MuyuNavigationModel from 'muyu-navigation-model';
import DatePicker from './components/date-picker/src/date-picker'
import './assets/css/base.scss'
import unit from './unit/plugin';
// import hy from './unit/index'
import * as echarts from 'echarts';
import './plugin/base64';

Vue.config.productionTip = false;
const chargeWay = [
  { id: 10, value: '微信' },
  // {id:20,value:'支付宝'},
  { id: 30, value: '现金' },
  { id: 31, value: '扫码' },
  { id: 32, value: '扫码枪' },
  { id: 33, value: '刷卡' },
  { id: 34, value: '对公转账' },
  { id: 38, value: '其他' }
  // { id: 35, value: '现金补录' },
  // { id: 36, value: '扫码补录' },
  // { id: 37, value: '扫码枪补录' },

  // {id:96,value:'管理缴费'},
  // {id:97,value:'余额'},
  // {id:98,value:'银行卡'},
  // {id:99,value:'花呗'}
]
const setMoney = (data, values) => {
  if (!data || !values) {
    return console.warn("缺少值")
  }
  let value = data[values].toString();
  let index = value.indexOf('.');
  let len = value.substring(index, value.length).length;
  if (index > -1 && len > 3) {
    data[values] = value.substring(0, index + 3)
  }
}
Vue.prototype.$echarts = echarts;
Vue.prototype.$chargeWay = chargeWay;
Vue.prototype.$setMoney = setMoney
Vue.use(MuyuNavigationModel)
Vue.use(ElementUI);
Vue.use(unit);
// Vue.use(hy)
Vue.component(DatePicker.name, DatePicker)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
