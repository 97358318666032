import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { routeArr } from "../plugin/navlist";
import list from "../plugin/leftNav";
import { Message } from "element-ui";
import { refresh, hasRole } from "../plugin/common";

Vue.use(VueRouter);
const routes = [
  {
    name: "home",
    path: "/",
    meta: {
      title: "登录",
    },
    redirect: "/login",
  },
  {
    name: "login",
    path: "/login",
    meta: {
      title: "登录",
    },
    component: () => import("../views/login"),
  },
  {
    name: "index",
    path: "/index",
    meta: {
      title: "首页",
    },
    component: () => import("../views/index.vue"),
  },
  {
    name: "404",
    path: "/404",
    meta: {
      title: "404",
    },
    component: () => import("../views/nofound.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
routeArr.forEach((item) => {
  router.addRoute("index", item);
});
let roleIds = [];
function setNavList(data) {
  if (!Array.isArray(data)) return;
  data.forEach((item, index) => {
    //roleIds.includes(item.id)
    if (hasRole(item.id)) {
      item.ispermission = true;
    } else {
      item.ispermission = false;
    }
    if (item.child) {
      setNavList(item.child);
    }
  });
}
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  let arr = ["/login", "/404", "/"];
  roleIds = store.state.roleIds || [];
  if (roleIds.length == 0) {
    let token = window.localStorage.getItem("token");
    if (token && token !== "null") {
      roleIds = await refresh();
      setNavList(list);
    }
  }
  if (arr.includes(to.path)) {
    next();
    return false;
  }
  if (to.meta.roleId || to.meta.othId) {
    if (hasRole(to.meta.roleId) || hasRole(to.othId)) {
      next();
      return false;
    }
    next("/404");
    return false;
  }

  next();
  // next({...to, replace: true})
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
