import { getDataList } from "../views/meterManage/service/meter";

export const meter = {
  namespaced: true,
  state: {
    meterManageList: [],
    meterManageCount: 0,
    rechargeRecordList: [],
    rechargeRecordCount: 0,
    electricTotalList: [],
    electricTotalCount: 0,
    clearRecordList: [],
    clearRecordCount: 0,
    merchantElectricTotalList: [],
    merchantElectricTotalCount: 0,
    water_meterManageList: [],
    water_meterManageCount: 0,
    water_rechargeRecordList: [],
    water_rechargeRecordCount: 0,
    water_electricTotalList: [],
    water_electricTotalCount: 0,
    water_clearRecordList: [],
    water_clearRecordCount: 0,
    water_merchantTotalList: [],
    water_merchantTotalCount: 0,
  },
  getters: {
    pageListData(state) {
      return (pageName) => {
        return state[`${pageName}List`];
      };
    },
    pageCountData(state) {
      return (pageName) => {
        return state[`${pageName}Count`];
      };
    },
  },
  mutations: {
    changeMeterManageList(state, value) {
      state.meterManageList = value;
    },
    changeMeterManageCount(state, count) {
      state.meterManageCount = count;
    },
    chnageRechargeRecordList(state, value) {
      state.rechargeRecordList = value;
    },
    chnageRechargeRecordCount(state, count) {
      state.rechargeRecordCount = count;
    },
    changeElectricTotalList(state, value) {
      state.electricTotalList = value;
    },
    changeElectricTotalCount(state, count) {
      state.electricTotalCount = count;
    },
    changeClearRecordList(state, value) {
      state.clearRecordList = value;
    },
    changeClearRecordCount(state, count) {
      state.clearRecordCount = count;
    },
    changeMerchantElectricTotalList(state, value) {
      state.merchantElectricTotalList = value;
    },
    changeMerchantElectricTotalCount(state, count) {
      state.merchantElectricTotalCount = count;
    },
    changeWaterMeterManageList(state, value) {
      state.water_meterManageList = value;
    },
    changeWaterMeterManageCount(state, count) {
      state.water_meterManageCount = count;
    },
    chnageWaterRechargeRecordList(state, value) {
      state.water_rechargeRecordList = value;
    },
    chnageWaterRechargeRecordCount(state, count) {
      state.water_rechargeRecordCount = count;
    },
    changeWaterElectricTotalList(state, value) {
      state.water_electricTotalList = value;
    },
    changeWaterElectricTotalCount(state, count) {
      state.water_electricTotalCount = count;
    },
    changeWaterClearRecordList(state, value) {
      state.water_clearRecordList = value;
    },
    changeWaterClearRecordCount(state, count) {
      state.water_clearRecordCount = count;
    },
    changeWaterMerchantElectricTotalList(state, value) {
      state.water_merchantTotalList = value;
    },
    changeWaterMerchantElectricTotalCount(state, count) {
      state.water_merchantTotalCount = count;
    },
  },
  actions: {
    async getMeterManageListAction({ commit }, payload) {
      const { pageName, params } = payload;
      //type 1水 2电
      let url = "";
      switch (pageName) {
        case "meterManage":
        case "water_meterManage":
          url = "/meterItem/list";
          break;
        case "rechargeRecord":
        case "water_rechargeRecord":
          url = "/meterItemPay/list";
          break;
        case "electricTotal":
        case "water_electricTotal":
          url = "/meterItem/list";
          break;
        case "clearRecord":
        case "water_clearRecord":
          url = "/meterItem/list";
          break;
        case "merchantElectricTotal":
        case "water_merchantTotal":
          url = "/meterItem/list";
          break;
        default:
          break;
      }
      const { data: result } = await getDataList(url, params);
      switch (pageName) {
        case "meterManage":
          commit("changeMeterManageList", result?.list ?? []);
          commit("changeMeterManageCount", result.total);
          break;
        case "water_meterManage":
          commit("changeWaterMeterManageList", result?.list ?? []);
          commit("changeWaterMeterManageCount", result.total);
          break;
        case "rechargeRecord":
          commit("chnageRechargeRecordList", result?.list ?? []);
          commit("chnageRechargeRecordCount", result.total);
          break;
        case "water_rechargeRecord":
          commit("chnageWaterRechargeRecordList", result?.list ?? []);
          commit("chnageWaterRechargeRecordCount", result.total);
          break;
        case "electricTotal":
          commit("changeElectricTotalList", result?.list ?? []);
          commit("changeElectricTotalCount", result.total);
          break;
        case "water_electricTotal":
          commit("changeWaterElectricTotalList", result?.list ?? []);
          commit("changeWaterElectricTotalCount", result.total);
          break;
        case "clearRecord":
          commit("changeClearRecordList", result?.list ?? []);
          commit("changeClearRecordCount", result.total);
          break;
        case "water_clearRecord":
          commit("changeWaterClearRecordList", result?.list ?? []);
          commit("changeWaterClearRecordCount", result.total);
          break;
        case "merchantElectricTotal":
          commit("changeMerchantElectricTotalList", result?.list ?? []);
          commit("changeMerchantElectricTotalCount", result.total);
          break;
        case "water_merchantTotal":
          commit("changeWaterMerchantElectricTotalList", result?.list ?? []);
          commit("changeWaterMerchantElectricTotalCount", result.total);
          break;
        default:
          break;
      }
    },
  },
};
