function getBlobByContent (content, options) {
    if (window.Blob) {
      return new Blob([content], { type: `text/${options.type};charset=utf-8;` })
    }
    return null
  }
  function toValueString (obj) {
    if (isNumber(obj)) {
      return toNumberString(obj)
    }
    return '' + (eqNull(obj) ? '' : obj)
  }
  
  function eqNull (obj) {
    return obj === null || obj === 'undefined'
  }


  function toNumberString(num) {
    var rest = '' + num
    var scienceMatchs = rest.match(/^([-+]?)((\d+)|((\d+)?[.](\d+)?))e([-+]{1})([0-9]+)$/)
    if (scienceMatchs) {
      var isNegative = num < 0
      var absFlag = isNegative ? '-' : ''
      var intNumStr = scienceMatchs[3] || ''
      var dIntNumStr = scienceMatchs[5] || ''
      var dFloatNumStr = scienceMatchs[6] || ''
      var sciencFlag = scienceMatchs[7]
      var scienceNumStr = scienceMatchs[8]
      var floatOffsetIndex = scienceNumStr - dFloatNumStr.length
      var intOffsetIndex = scienceNumStr - intNumStr.length
      var dIntOffsetIndex = scienceNumStr - dIntNumStr.length
      if (sciencFlag === '+') {
        if (intNumStr) {
          return absFlag + intNumStr + helperStringRepeat('0', scienceNumStr)
        }
        if (floatOffsetIndex > 0) {
          return absFlag + dIntNumStr + dFloatNumStr + helperStringRepeat('0', floatOffsetIndex)
        }
        return absFlag + dIntNumStr + helperNumberOffsetPoint(dFloatNumStr, scienceNumStr)
      }
      if (intNumStr) {
        if (intOffsetIndex > 0) {
          return absFlag + '0.' + helperStringRepeat('0', Math.abs(intOffsetIndex)) + intNumStr
        }
        return absFlag + helperNumberOffsetPoint(intNumStr, intOffsetIndex)
      }
      if (dIntOffsetIndex > 0) {
        return absFlag + '0.' + helperStringRepeat('0', Math.abs(dIntOffsetIndex)) + dIntNumStr + dFloatNumStr
      }
      return absFlag + helperNumberOffsetPoint(dIntNumStr, dIntOffsetIndex) + dFloatNumStr
    }
    console.log(scienceMatchs,rest);
    return rest
  }

  function saveLocalFile(options) {
    var filename = options.filename,
        type = options.type,
        content = options.content;
    var name = "".concat(filename, ".").concat(type);
    if (window.Blob) {
      var blob = content instanceof Blob ? content : getBlobByContent(toValueString(content), options);
  
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, name);
      } else {
        var url = URL.createObjectURL(blob);
        var linkElem = document.createElement('a');
        linkElem.target = '_blank';
        linkElem.download = name;
        linkElem.href = url;
        document.body.appendChild(linkElem);
        linkElem.click();
        document.body.removeChild(linkElem);
        requestAnimationFrame(function () {
          if (linkElem.parentNode) {
            linkElem.parentNode.removeChild(linkElem);
          }
  
          URL.revokeObjectURL(url);
        });
      }
    }
  }
  function saveFile(url,name="下载",type){
    fetch(url).then(response => {
        response.blob().then(blob => {
          saveLocalFile({ filename:name + new Date().toLocaleString(), type, content: blob })
        })
      })
  }
  module.exports = saveFile