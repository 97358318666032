import * as request from "@/plugin/request";

const getAssemblyData = (item) => {
  return new Promise((resolve, reject) => {
    request
      .HttpGet("/contract/stallList", {
        pageNum: 1,
        pageSize: 999999,
        property_type_id: item.property_type_id,
      })
      .then((res) => {
        resolve(res);
      })
      .catch(() => {
        reject();
      });
  });
};

const mergeData = (original, merge) => {
  merge = JSON.parse(JSON.stringify(merge));
  original = JSON.parse(JSON.stringify(original));
  const arr = [];
  for (let i in original) {
    let dataObj = {
      ...original[i],
      coupon_price:
        (merge[i].coupon_price * 100 + original[i].coupon_price * 100) / 100,
      give_discount:
        (merge[i].give_discount * 100 + original[i].give_discount * 100) / 100,
      key_pay_price:
        (merge[i].key_pay_price * 100 + original[i].key_pay_price * 100) / 100,
      key_total_price:
        (merge[i].key_total_price * 100 + original[i].key_total_price * 100) /
        100,
      key_update_price:
        (merge[i].key_update_price * 100 + original[i].key_update_price * 100) /
        100,
      price_ratio:
        (merge[i].price_ratio * 100 + original[i].price_ratio * 100) / 100,
      zero_price:
        (merge[i].zero_price * 100 + original[i].zero_price * 100) / 100,
    };
    arr.push(dataObj);
  }
  return arr;
};

const setDataTotal = (data, original) => {
  for (let i in data) {
    data[i].bill_key = (data[i].bill_key * 100 + original[i].bill_key * 100) / 100;
    data[i].coupon_price = (data[i].coupon_price * 100 + original[i].coupon_price * 100) / 100;
    data[i].give_discount = (data[i].give_discount * 100 + original[i].give_discount * 100) / 100;
    data[i].key_pay_price = (data[i].key_pay_price * 100 + original[i].key_pay_price * 100) / 100;
    data[i].key_total_price = (data[i].key_total_price * 100 + original[i].key_total_price * 100) / 100;
    data[i].key_update_price = (data[i].key_update_price * 100 + original[i].key_update_price * 100) / 100;
    data[i].price_ratio = (data[i].price_ratio * 100 + original[i].price_ratio * 100) / 100;
    data[i].zero_price = (data[i].zero_price * 100 + original[i].zero_price * 100) / 100;
  }
  return data;
};
const setData = (data, original) => {
  for (let i in data) {
    for (let j in data[i].child) {
      data[i].child[j].data.forEach((item) => {
        item.bill_key = 0;
        item.coupon_price = 0;
        item.give_discount = 0;
        item.key_pay_price = 0;
        item.key_total_price = 0;
        item.key_update_price = 0;
        item.price_ratio = 0;
        item.zero_price = 0;
      });
    }
  }
  for (let i in data) {
    for (let j in data[i].child) {
      for (let k in original) {
        if (data[i].child[j].title == original[k].title && data[i].child[j].year_title == original[k].year_title) {
          data[i].child[j].data = setDataTotal(
            data[i].child[j].data,
            original[k].data
          );
        }
      }
    }
  }
  return data;
};

/**
 * [dataClassify 处理汇总数据]
 * @param  {[type]} data               [data]
 * @return {[type]}      [data]
 */
const dataClassify = (data) => {
  const originalData = JSON.parse(JSON.stringify(data));
  const storeData = JSON.parse(JSON.stringify(data));
  const tempData = JSON.parse(JSON.stringify(data));
  let colList = [];
  const colListArr = [];
  let storeColList = [];
  let year_title = "";
  let merchants_id = "-1";
  let merchants_stall_id = "-1";
  for (let i = 0; i < data.length; i++) {
    if (year_title != data[i].year_title) {
      year_title = data[i].year_title;
      if (data[i].merchants_id) {
        merchants_id = data[i].merchants_id;
      } else {
        merchants_stall_id = data[i].merchants_stall_id;
      }

      let temp = {
        year_title: data[i].year_title,
        child: [],
      };
      for (let j = i; j < originalData.length; j++) {
        if (originalData[j].year_title == year_title) {
          if (merchants_id != "-1" && merchants_id != "") {
            if (merchants_id == originalData[j].merchants_id) {
              temp.child.push(originalData[j]);
            } else {
              let tempArr = JSON.stringify(originalData[j].data);
              temp.child[temp.child.length - 1].data = mergeData(
                temp.child[temp.child.length - 1].data,
                JSON.parse(tempArr)
              );
            }
          } else {
            if (merchants_stall_id == originalData[j].merchants_stall_id) {
              temp.child.push(originalData[j]);
              // tempMonth.child.push(originalData[j]);
            } else {
              let tempArr = JSON.stringify(originalData[j].data);
              temp.child[temp.child.length - 1].data = mergeData(
                temp.child[temp.child.length - 1].data,
                JSON.parse(tempArr)
              );
            }
          }
        }
      }
      colListArr.push(temp);
    }
  }
  colList = setData(JSON.parse(JSON.stringify(colListArr)), tempData);
  storeColList = setBreakdown(
    JSON.parse(JSON.stringify(colListArr)),
    storeData
  );
  return {
    arr: colList,
    storeArr: storeColList,
  };
};

const setBreakdown = (data, originalData) => {
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].child.length; j++) {
      let obj = {
        title: data[i].child[j].title,
        child: {},
      };
      for (let k = 0; k < originalData.length; k++) {
        if (
          originalData[k].year_title == data[i].child[j].year_title &&
          originalData[k].title == data[i].child[j].title
        ) {
          if (originalData[k].merchants_id) {
            obj.child[originalData[k].merchants_id] = originalData[k];
          } else {
            obj.child[originalData[k].merchants_stall_id] = originalData[k];
          }
        }
      }
      data[i].child[j] = obj;
    }
  }
  return data;
};

const assembleData = (feeCharge, data) => {
  let colList = [];
  let storeColListArr = [];
  for (let i in feeCharge) {
    let dataArr = dataClassify(data);
    colList = dataArr.arr;
    feeCharge[i].child = dataArr.arr;
    storeColListArr = dataArr.storeArr;
  }
  return {
    feeCharge,
    colList,
    storeColListArr,
  };
};

const dataStoreClassify = (item, data, name) => {
  for (let i in data) {
    if (data[i][name] == item[name]) {
      let tempObj = {};
      for (let j in data[i].data) {
        if (item.fee_id == data[i].data[j].property_type_id) {
          tempObj = data[i].data[j];
          break;
        }
      }
      item = {
        payType: tempObj,
        name: data[i].name,
        title: data[i].title,
        year_title: data[i].year_title,
      };
      break;
    }
  }
  return item;
};

const assembleStoreData = (form, data) => {
  let dataArr = [];
  if (form.merchants_ids.length > 0) {
    for (let i in form.merchants_ids) {
      for (let j in form.fee_charge) {
        if (j == 0) {
          dataArr.push({
            merchants_id: form.merchants_ids[i],
            ...form.fee_charge[j],
            rowspan: form.fee_charge.length,
          });
        } else {
          dataArr.push({
            merchants_id: form.merchants_ids[i],
            ...form.fee_charge[j],
          });
        }
      }
    }
    for (let i in dataArr) {
      let item = dataStoreClassify(dataArr[i], data, "merchants_id");
      dataArr[i] = {
        ...item,
        ...dataArr[i],
      };
    }
  } else {
    let propertyType = [];
    for (let i in form.property_type) {
      propertyType = propertyType.concat(
        form.property_type[i].merchants_stall_id
      );
    }
    for (let i in propertyType) {
      for (let j in form.fee_charge) {
        if (j == 0) {
          dataArr.push({
            merchants_stall_id: propertyType[i],
            ...form.fee_charge[j],
            rowspan: form.fee_charge.length,
          });
        } else {
          dataArr.push({
            merchants_stall_id: propertyType[i],
            ...form.fee_charge[j],
          });
        }
      }
    }
    for (let i in dataArr) {
      let item = dataStoreClassify(dataArr[i], data, "merchants_stall_id");
      dataArr[i] = {
        ...item,
        ...dataArr[i],
      };
    }
  }
  return dataArr;
};

export const dataReport = {
  namespaced: true,
  state: {
    yearArr: [],
    monthArr: [],
    storeType: [],
    charges: [],
    tempMonth: [],
    tableData: [],
    colList: [],
    loading: false,
    subentry: [],
    merchantsListMap: [],
    subentryColList: [],
    stallTypeMap: [],
    payList: [
      {
        name: "应收金额（元）",
        checked: "total_status",
        field: "key_total_price",
        show: false,
      },
      {
        name: "已收金额（元）",
        checked: "pay_status",
        field: "key_update_price",
        show: false,
      },
      {
        name: "未收金额（元）",
        checked: "not_pay_status",
        field: "key_pay_price",
        show: false,
      },
      {
        name: "减免金额",
        checked: "discount_status",
        field: "give_discount",
        show: false,
      },
      {
        name: "抹零金额",
        checked: "zero_price_status",
        field: "zero_price",
        show: false,
      },
      {
        name: "优惠金额",
        checked: "coupon_status",
        field: "coupon_price",
        show: false,
      },
      {
        name: "收费率(%)",
        checked: "rate_status",
        field: "price_ratio",
        show: false,
      },
    ],
    form: {
      year_date: [],
      merchants_ids: [],
      merchants_id: 0,
      property_type: [],
      fee_charge: [],
      pay_charge: {
        total_status: false,
        pay_status: false,
        not_pay_status: false,
        discount_status: false,
        zero_price_status: false,
        coupon_status: false,
        rate_status: false,
      },
    },
  },
  mutations: {
    initYear (state) {
      const year = new Date().getFullYear() + 5;
      const tempArr = [];
      for (let i = 1990; i <= year; i++) {
        tempArr.unshift({
          name: i.toString() + "年",
          value: i.toString(),
          checked: false,
          disabled: false,
        });
      }
      state.yearArr = tempArr;
    },
    initMonth (state) {
      const month = [];
      for (let i = 1; i <= 12; i++) {
        if (i < 10) {
          month.push({
            name: "0" + i + "月",
            value: "0" + i,
            checked: false,
            disabled: false,
          });
        } else {
          month.push({
            name: i + "月",
            value: i.toString(),
            checked: false,
            disabled: false,
          });
        }
      }
      state.monthArr = month;
    },
    initPropertyType (state, data) {
      state.form.property_type.push({
        name: data.property_type_id,
        merchants_stall_id: [],
      });
    },
    /**
     * [setStoreType 设置商铺类型]
     * @param {[type]} state  [state]
     * @param {[type]} data   [商铺类型]
     */
    setStoreType (state, data) {
      state.storeType = [
        ...data,
        // {
        //   name: "临时商户",
        //   property_type_id: "-1",
        //   checked: false,
        //   data: [],
        // },
      ];
    },
    /**
     * [setCharges 设置收费项目]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setCharges (state, data) {
      let temp = [
        ...data,
        // {
        //   id: 38,
        //   rent_type: 30,
        //   contract_fee_name: "其它费用",
        // },
      ];
      temp.forEach((item, index) => {
        item.valueChecked = parseInt(index) + 1;
      });
      state.charges = temp;
    },
    /**
     * [setTempMonth 清空临时月份]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setTempMonth (state) {
      state.tempMonth = [];
    },
    /**
     * [setTableData 设置表格数据]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setTableData (state, data) {
      state.tableData = data;
    },
    /**
     * [setColList 设置表格内的数据]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setColList (state, data) {
      state.colList = data;
    },
    /**
     * [setLoading 设置加载状态]
     * @param {[type]} state    [state]
     * @param {[type]} loading  [loading]
     */
    setLoading (state, loading) {
      state.loading = loading;
    },
    /**
     * [setSubentry 分析数据汇总]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setSubentry (state, data) {
      state.subentry = data;
    },
    /**
     * [setMerchantsListMap 设置商户键值对]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setMerchantsListMap (state, data) {
      state.merchantsListMap = data;
    },
    setSubentryColList (state, data) {
      state.subentryColList = data;
    },
    setStallTypeMap (state, data) {
      state.stallTypeMap = data;
    },
    clearState (state) {
      state.yearArr = [];
      state.monthArr = [];
      state.storeType = [];
      state.charges = [];
      state.tempMonth = [];
      state.tableData = [];
      state.colList = [];
      state.loading = false;
      state.subentry = [];
      state.merchantsListMap = [];
      state.subentryColList = [];
      state.stallTypeMap = [];
      state.form = {
        year_date: [],
        merchants_ids: [],
        merchants_id: 0,
        property_type: [],
        fee_charge: [],
        pay_charge: {
          total_status: false,
          pay_status: false,
          not_pay_status: false,
          discount_status: false,
          zero_price_status: false,
          coupon_status: false,
          rate_status: false,
        },
      };
      state.payList = [
        {
          name: "应收金额（元）",
          checked: "total_status",
          field: "key_total_price",
          show: false,
        },
        {
          name: "已收金额（元）",
          checked: "pay_status",
          field: "key_update_price",
          show: false,
        },
        {
          name: "未收金额（元）",
          checked: "not_pay_status",
          field: "key_pay_price",
          show: false,
        },
        {
          name: "减免金额",
          checked: "discount_status",
          field: "give_discount",
          show: false,
        },
        {
          name: "抹零金额",
          checked: "zero_price_status",
          field: "zero_price",
          show: false,
        },
        {
          name: "优惠金额",
          checked: "coupon_status",
          field: "coupon_price",
          show: false,
        },
        {
          name: "收费率",
          checked: "rate_status",
          field: "price_ratio",
          show: false,
        },
      ];
    },
  },
  actions: {
    /**
     * [getStoreType 获取商铺类型]
     * @param  {[type]} commit               [description]
     * @return {[type]}        [description]
     */
    getStoreType ({ commit }) {
      request
        .HttpGet("/type/list", {
          pageNum: 1,
          pageSize: 9999,
        })
        .then(async (res) => {
          const data = [];
          data.push({
            name: "商户数据",
            property_type_id: "-1",
            checked: false,
            data: [],
          });
          commit("initPropertyType", data[0]);
          const merchantsList = await request.HttpPost("/merchants/list", {
            pageNum: 1,
            pageSize: 9999,
          });
          if (merchantsList.code == 0) {
            data[0].data = merchantsList.data.list || [];
            let arr = {};
            for (let i in merchantsList.data.list) {
              arr[merchantsList.data.list[i].merchants_id] =
                merchantsList.data.list[i];
            }
            commit("setMerchantsListMap", arr);
          }
          if (res.data && res.data.list.length > 0) {
            let arr = [];
            for (let i in res.data.list) {
              await getAssemblyData(res.data.list[i]).then((list) => {
                data.push({
                  ...res.data.list[i],
                  checked: false,
                  data: list.data.list || [],
                });
                arr = arr.concat(list.data.list || []);
                commit("initPropertyType", res.data.list[i]);
              });
            }
            let obj = {};
            for (let j in arr) {
              obj[arr[j].merchants_stall_id] = arr[j];
            }
            commit("setStallTypeMap", obj);
          }
          commit("setStoreType", data);
        });
    },
    getCharges ({ commit }) {
      request.HttpPost("/contract_fee_setting/listAll").then(async (res) => {
        if (res.data) {
          res.data.forEach((item) => {
            item.checked = false;
            item.typeFlag = item.type;
          });
        }
        const dailySetting = await request.HttpGet("/setting/dailySetting");
        let data = [];
        if (dailySetting.code == 0) {
          dailySetting.data.values_struct.forEach((item) => {
            item.id = item.children.property_setting_id;
            item.contract_fee_name = item.type_name;
            item.typeFlag = 30;
          });
          data = dailySetting.data.values_struct;
        }

        commit("setCharges", res.data.concat(data || []));
      });
    },
    searchIndex ({ commit }, form) {
      request.HttpPost("/report/index", form).then((res) => {
        let fee_charge = [];
        if (!form.fee_charge && form.merchants_id == -1) {
          fee_charge.push({
            name: "临时商品数据",
            fee_id: 0,
            type: 10,
          });
        } else {
          fee_charge = JSON.parse(JSON.stringify(form.fee_charge));
        }
        const storeArr = assembleStoreData(form, res.data);
        commit("setSubentry", storeArr);
        const tempaObj = assembleData(fee_charge, res.data);
        commit("setSubentryColList", tempaObj.storeColListArr);
        commit("setTableData", tempaObj.feeCharge);
        commit("setColList", tempaObj.colList);
        commit("setLoading", false);
      }).catch(() => {
        commit("setLoading", false);
      });
    },
  },
};
