<template>
    <div class="myselect" @click.stop="listshow = !listshow">
        <span class="selectvalue" v-if="modelValue">{{ setvalue }}</span>
        <span class="selectvalue" v-else>{{ placeholder }}</span>
        <div class="sjbar" v-if="listshow"></div>
        <transition name="down">
            <div class="mylist" v-if="listshow">
                <div class="myitem"
                    :class="item[options.value] == modelValue ? 'select' : ''"
                    v-for="item in list"
                    @click.stop="selected(item)"
                    :key="item[options.value]"
                >{{ item[options.label] }}</div>
            </div>
        </transition>
        <div class="icons">
    <i class="myicon el-icon-circle-close"  v-if="modelValue" @click.stop="clearValue" @click="clear" ></i>
    </div>
        <!-- <el-icon :size="16">
            <close class="myicon" v-if="modelValue" @click.stop="clearValue" />
        </el-icon>
        <el-icon :szie="20" class="myicon" :class="listshow?'down':''">
            <ArrowDown></ArrowDown>
        </el-icon> -->
    </div>
</template>
<script>

export default {
    props: {
        modelValue: {
            type: [String, Number],
        },
        placeholder: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: () => []
        },
        options: {
            type: Object,
            default: () => {
                return { value: '', label: '' };
            }
        }
    },
    data() {
        return {
            listshow: false,
            value: ''
        };
    },
    computed: {
        setvalue() {
            if (this.value) {
                return this.value[this.options.label];
            }
            return this.placeholder;
        }
    },
    watch: {
        modelValue: {
            handler(value) {
                for (let i of this.list) {
                    if (i[this.options.value] == value) {
                        this.value = i;
                    }
                }
            },
            immediate: true
        }
    },
    mounted() {
        document.body.addEventListener('click', () => {
            this.listshow = false;
        });
    },
    methods: {
        selected(e) {
            this.listshow = false;
            this.value = e;
            this.$emit('update:modelValue', e[this.options.value]);
            this.$emit('change', e);
        },
        clearValue() {
            this.value = '';
            this.$emit('update:modelValue', '');
        }
    }
};
</script>
<style lang="scss" scoped>
.myselect {
    min-width: 220px;
    height: 32px;
    border: 1px solid #dedede;
    z-index: 99;
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
   color: #918a8a;
    .selectvalue {
        width: 100%;
        height: 100%;
        display: inline-block;
        flex: 1;
        padding: 0 15px;
        box-sizing: border-box;
    }
    .sjbar {
        position: absolute;
        top: 37px;
        left: 50%;
        width: 8px;
        height: 8px;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #fff;
        z-index: 9;
        box-shadow: 0 -1px 0px 0 #dedede, -1px 0 0 #dedede;
    }
    .mylist {
        width: 100%;
        position: absolute;
        top: 45px;
        box-shadow: 0px 0px 5px #dedede;
        background: #ffffff;
        overflow: hidden;
        padding-top: 4px;
        box-sizing: border-box;
        .myitem {
            padding-left: 15px;
            box-sizing: border-box;
            cursor: pointer;
            z-index: 989999;
            &:hover {
                background-color: rgba(#000000, 0.1);
                color: #3d7eff;
            }
        }
        .select {
            color: #3d7eff;
        }
    }
    .myicon {
        margin-right: 5px;
        font-size: 18px;
        cursor: pointer;
        transition: all .2s;
    }
    .down{
        transform: rotate(180deg);
    }
    .down-enter-from,
    .down-leave-to {
        height: 0;
    }
    .down-enter-active,
    .down-leave-active {
        transition: all 0.2s;
    }
    .down-enter-to,
    .down-leave-from {
        height: 100%;
    }
}
</style>
