const debounce = (fn, dele = 500) => {
    let timer = null;
    let simer = null
    return function (e) {
        let uid = e.target.__vue__._uid
        var th = this;
        var args = arguments;
        if (timer && uid == simer) return;
        fn.apply(th, args);
        simer = JSON.parse(JSON.stringify(uid));
        timer = setTimeout(() => {
            timer = null;
            simer = null
        }, dele);
    }
};
/**
 * @desc 函数防抖
 * @param func 目标函数 注意不能是箭头函数  因为 apply 改变不了 this
 * @param wait 延迟执行毫秒数
 * @param immediate true - 立即执行， false - 延迟执行
 */
const debounceA = (func, wait, immediate) => {
    let timer;
    return function () {
        let context = this,
            args = arguments;

        if (timer) clearTimeout(timer);
        if (immediate) {
            let callNow = !timer;
            timer = setTimeout(() => {
                timer = null;
            }, wait);
            if (callNow) func.apply(context, args);
        } else {
            timer = setTimeout(() => {
                func.apply(context, args);
            }, wait)
        }
    }
}

export {debounce, debounceA}
