<template>
  <div class="my-input" :class="[{disabled},{left},{right}, isFocus ? 'focusStyle' : '']" @mouseenter="mouseenter" @mouseleave="hover = false">
    <div class="my-input-title">
      <slot name="pre"></slot>
    </div>
    <div class="my-input-box" >
      <input @keydown="keydown" :type="type" :class="[disabled ? 'inputdisabled' : '']" @input="inputed" @focus="isFocus = true" @blur="blur"  :disabled="disabled" :value="value" :placeholder="placeholder" />
    </div>
    <div class="myicons">
    <i class="el-icon-circle-close" v-if="hover" @click="clear"></i>
    </div>
    <div class="search">
      <slot name="search"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    'value': {
    },
    'placeholder': {
      type: String
    },
    disabled:{
      type:Boolean,
      default:false
    },
    left:{
			type:Boolean
		},
		right:{
			type:Boolean
		},
    type:{
      type:String,
      default:'text'
    },
    posNumber:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      hover: false,
      isFocus: false
    };
  },
  watch: {
    value: {
      handler(e) {
        e && !this.disabled ? this.hover = true : this.hover = false;
      },
      immediate: true
    }
  },
  methods: {
    inputed(e) {
      let value = e.target.value
      if(this.posNumber && value < 0){
         value = 0
      }
      this.$emit('input', value);
    },
    blur(){
      this.isFocus = false;
    },
    mouseenter() {
      this.value && !this.disabled ? this.hover = true : this.hover = false;
    },
    mouseleave() {
      this.hover = false;
    },
    keydown(e){
       this.$emit('keydown',e)
    },
    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');
    }
  }
};
</script>
<style lang="scss">
.my-input {
  min-width: 180px;
  height: 30px;
  font-size: 12px;
  border: 1px solid #e4e7ed;
  display: inline-flex;
  align-items: center;
  z-index: 99;
  user-select: none;
  vertical-align: middle;
  
  &.focusStyle {
    border: 1px solid #528CFF;
  }
  // padding: 0 10px;
  .my-input-title{
    padding-left: 5px;
    box-sizing: border-box;
    font-size: 12px;
    color: #333333;
    // font-weight: bolder;
  }
  .my-input-box {
    flex: 1;
    height: 32px;
    padding: 0;
    user-select: none;
    input {
      width: 100%;
      height: 100%;
      padding-left: 10px;
      //  pointer-events: none;
      z-index: -1;
      margin: 0;
      background: none;
      vertical-align: top;
      font-size: 12px;
      user-select: none;
      &::placeholder {
        font-size: 12px;
        color: #cbced4;
      }
    }
  }

  .myicons{
    width: 25px;
    height: 100%;
    line-height: 32px;
     margin: 0 5px;
     text-align: center;
     font-size: 15px;
     color: #999999;
  }
  i {
    cursor: pointer;

  }
  .search{
    padding-right: 5px;
  }
}
.disabled{
  cursor:not-allowed ;
  background-color: #fdfbfb;
  &:hover{
    cursor: not-allowed;
  }
}
.inputdisabled{
   &:hover{
    cursor: not-allowed;
  }
}

.left{
	margin-left: 15px;
}
.right{
	margin-right: 15px;
}
</style>
