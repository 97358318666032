import { Notification } from "element-ui";
import { HttpPost } from "../plugin/request";
import store from "@/store";

/**
 * [notifySuccess 成功提示]
 * @param  {[String]} msg [提示内容]
 */
export const notifySuccess = (msg) => {
  Notification({
    title: "成功",
    message: msg,
    type: "success",
  });
};
// 抹零 1 分 2 角  3 十元以下
export const reserveDecimalFour = (value, len) => {
  if (value) {
    let arr = value.toString().split(".");
    if (len == 3) {
      let val = arr[0].split("");
      val.splice(val.length - 1, 1, "0");
      return val.join("");
    } else if (len == 2) {
      return arr[0];
    } else if (len == 1) {
      let val = arr[0] + "." + arr[1].split("").slice(0, 1).join("");
      return val;
    }
  } else {
    return "0.00";
  }
};

// 数字加逗号
export const toThousands = (num = 0) => {
  return num.toString().replace(/\d+/, function (n) {
    return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  });
}

export const outputmoney = (number) => {
  number = number.toString();
  number = number.replace(/\,/g, "");
  if (isNaN(number) || number == "") return "";
  number = Math.round(number * 100) / 100;
  if (number < 0)
    return '-' + outputdollars(Math.floor(Math.abs(number) - 0) + '') + outputcents(Math.abs(number) - 0);
  else
    return outputdollars(Math.floor(number - 0) + '') + outputcents(number - 0);
}
//格式化金额
const outputdollars = (number) => {
  if (number.length <= 3)
    return (number == '' ? '0' : number);
  else {
    var mod = number.length % 3;
    var output = (mod == 0 ? '' : (number.substring(0, mod)));
    for (let i = 0; i < Math.floor(number.length / 3); i++) {
      if ((mod == 0) && (i == 0))
        output += number.substring(mod + 3 * i, mod + 3 * i + 3);
      else
        output += ',' + number.substring(mod + 3 * i, mod + 3 * i + 3);
    }
    return (output);
  }
}
const outputcents = (amount) => {
  amount = Math.round(((amount) - Math.floor(amount)) * 100);
  return (amount < 10 ? '.0' + amount : '.' + amount);
}


export const intToChinese = (amount) => {
  if (isNaN(amount) || amount >= 1000000000000) return "无效金额！";  //数值最大不超过1万亿
  var sPrefix = amount < 0 ? "(负)" : "";                             //将负号‘-’显示成汉字‘(负)’
  var sAmount = Math.abs(amount).toFixed(2);                          //格式化成两位小数格式（两位小数对应“'角”和“分”）
  var sUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';                         //14个权位单位
  var sCapital = '零壹贰叁肆伍陆柒捌玖';                              //10个大写数字
  sAmount = sAmount.replace(".", "");                                 //去除小数点（使数字与权位对应）
  sUnit = sUnit.substr(sUnit.length - sAmount.length);                //截取权位
  var sOutput = "";
  for (var i = 0, len = sAmount.length; i < len; i++) {               //拼接数字与权位单位
    sOutput += sCapital.substr(sAmount.substr(i, 1), 1) + sUnit.substr(i, 1);

  }
  var r1 = sPrefix + sOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, "零元").replace(/零分/, "");
  //对零元、零角、零分进行处理
  r1 = r1.replace("零元", "").replace("零角", "零").replace("零分", "");
  //对特殊情况处理
  if (r1 == "整")   // 0,显示为“整”
    r1 = "零元整";
  else if (r1.indexOf("零") == 0) //  0.01 显示为“零壹份”
    r1 = r1.substr(1);
  return r1;
}

/**
 * [notifyError 失败提示]
 * @param  {[String]} msg [提示内容]
 */

export const notifyError = (msg) => {
  Notification({
    title: "错误",
    message: msg,
    type: "error",
  });
};

/**
 * [getDecimalsTwo 获取小数点后两位]
 * @param  {[type]} val               [数值]
 * @param  {[type]} len               [小数位数]
 * @return {[type]}     [description]
 */
export const getDecimalsTwo = (val, len = 2) => {
  val = val.toString();
  if (val.indexOf(".") == -1) {
    return parseFloat(val);
  }
  let split = val.split(".");
  split[1] = split[1].slice(0, len);
  return split.join(".");
};
export const getDecimalsOne = (val, len = 1) => {
  val = val.toString();
  if (val.indexOf(".") == -1) {
    return val;
  }
  let split = val.split(".");
  split[1] = split[1].slice(0, len);
  return split.join(".");
};

Date.daysInMonth = function (year, month) {
  if (month == 1) {
    if (year % 4 == 0 && year % 100 != 0) return 29;
    else return 28;
  } else if ((month <= 6 && month % 2 == 0) || (month = 6 && month % 2 == 1))
    return 31;
  else return 30;
};

// 增加月份
export const addMonth = (str, month) => {
  month = parseFloat(month);
  let date = new Date(Date.parse(str));
  var y = date.getFullYear();
  var m = date.getMonth();
  var nextY = y;
  var nextM = m;
  //如果当前月+要加上的月>11 这里之所以用11是因为 js的月份从0开始
  if (m + month > 11) {
    nextY = y + 1;
    nextM = parseInt(m + month) - 12;
  } else {
    nextM = date.getMonth() + month;
  }
  var daysInNextMonth = Date.daysInMonth(nextY, nextM);
  var day = date.getDate();
  if (day > daysInNextMonth) {
    day = daysInNextMonth;
  }
  return new Date(nextY, nextM, day);
};

/**
 * [formatDateTime 时间转换为年月日]
 * @param  {[type]} inputTime  [时间搓]
 * @return {[type]}           [description]
 */
export const formatDateTime = (inputTime) => {
  let date = new Date(inputTime);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? `0${m}` : m;
  let d = date.getDate();
  d = d < 10 ? `0${d}` : d;
  // let h = date.getHours();
  // h = h < 10 ? (`0${h}`) : h;
  // let minute = date.getMinutes();
  // let second = date.getSeconds();
  // minute = minute < 10 ? (`0${minute}`) : minute;
  // second = second < 10 ? (`0${second}`) : second;
  return `${y}-${m}-${d}`;
};
/**
 * [formatDateTimeHms 时间转换年月日时分秒]
 * @param  {[type]} inputTime [时间搓]
 * @return {[type]}           [description]
 */
export const formatDateTimeHms = (inputTime) => {
  let date = new Date(inputTime);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? `0${m}` : m;
  let d = date.getDate();
  d = d < 10 ? `0${d}` : d;
  let h = date.getHours();
  h = h < 10 ? `0${h}` : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? `0${minute}` : minute;
  second = second < 10 ? `0${second}` : second;
  return `${y}-${m}-${d} ${h}:${minute}:${second}`;
};

/**
 * [computationalAccuracy 计算价格或者重量，小数后面第三位向上取整]
 * @param  {[nubmer]} price [价格或者重量]
 * @return {[nubmer]}       [返回价格]
 */
export const computationalAccuracy = (price) => {
  let digit = 2;
  // 如果等于-1 那就是0位
  if (digit == -1) {
    digit = 0;
  }
  let monery = price.toString();
  let arr = monery.split(".");
  // 如果为整数就不管直接返回
  if (arr.length <= 1) {
    return price;
  }
  // 判断小数位数
  if (arr[1].length <= digit) {
    return parseFloat(price);
  }
  let decimals = "";
  for (let i = 0; i < arr[1].length; i++) {
    if (i == digit) {
      if (parseInt(arr[1][i]) > 0) {
        if (decimals.split("")[1] >= "9") {
          decimals = parseInt(decimals) + 1;
        } else if (decimals.split("")[1] < "9") {
          decimals =
            decimals.split("")[0] + (parseInt(decimals.split("")[1]) + 1);
        }
      }
      if (decimals == 100) {
        return parseFloat(arr[0]) + 1;
      } else {
        return parseFloat(arr[0] + "." + decimals);
      }
    }
    decimals += arr[1][i];
  }
  return parseFloat(price);
};

/**
 * [monthsBetw 计算两个日期相差的月份]
 * @param  {[type]} date1               [date1和date2是2019-3-12格式]
 * @param  {[type]} date2               [date1和date2是2019-3-12格式]
 * @return {[type]}       [description]
 */
export const monthsBetw = (date1, date2) => {
  if (!date1) {
    date1 = date2 ? date2 : formatDateTime(new Date().getTime());
  }
  if (!date2) {
    date2 = date1 ? date1 : formatDateTime(new Date().getTime());
  }
  //用-分成数组
  date1 = date1.split("-");
  date2 = date2.split("-");
  //获取年,月数
  let year1 = parseInt(date1[0]),
    month1 = parseInt(date1[1]),
    year2 = parseInt(date2[0]),
    month2 = parseInt(date2[1]),
    day1 = parseInt(date1[2]),
    day2 = parseInt(date2[2]);

  //通过年,月差计算月份差
  let months = (year2 - year1) * 12 + (month2 - month1);
  let day = 0;
  let sameMonth = 0;
  if (day2 >= day1) {
    months += 1;
    day = Math.abs(day2 - day1 - 1);
  } else if (day2 < day1 && day2 - day1 < -1) {
    months -= 1;
    day = Math.abs(day2 - day1 + 1);
  }
  switch (month2 - 1) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      sameMonth = 31;
      break;
    case 2:
      if (year2 % 400 == 0 || (year2 % 4 == 0 && year2 % 100 != 0)) {
        sameMonth = 29;
      } else {
        sameMonth = 28;
      }
      break;
    case 4:
    case 6:
    case 9:
    case 11:
      sameMonth = 30;
      break;
  }
  return {
    months,
    day: sameMonth - day,
    sameMonth,
  };
};
/**
 * 计算两个日期直接有多少个月
 * **/
//计算日期之间的月数
export const datemonth = (date1, date2) => {
  // 拆分年月日
  date1 = date1.split("-");
  // 得到月数
  date1 = parseInt(date1[0]) * 12 + parseInt(date1[1]);
  // 拆分年月日
  date2 = date2.split("-");
  // 得到月数
  date2 = parseInt(date2[0]) * 12 + parseInt(date2[1]);
  var m = Math.abs(date1 - date2);
  return m;
};
/**
 * [calculateMonth 合同计算月份，还有前后时间]
 * @param  {[type]} date1               [date1和date2是2019-3-12格式]
 * @param  {[type]} date2               [date1和date2是2019-3-12格式]
 * @return {[type]} [description]
 */
export const calculateMonth = (date1, date2) => {
  let monthNum = datemonth(date1, date2);
  if (!date1) {
    date1 = date2 ? date2 : formatDateTime(new Date().getTime());
  }
  if (!date2) {
    date2 = date1 ? date1 : formatDateTime(new Date().getTime());
  }
  let dayTemp = {
    frontDay: 0,
    laterDay: 0,
    months: 0,
  };
  //用-分成数组
  date1 = date1.split("-");
  date2 = date2.split("-");
  //获取年,月数
  let year1 = parseInt(date1[0]),
    month1 = parseInt(date1[1]),
    year2 = parseInt(date2[0]),
    month2 = parseInt(date2[1]),
    day1 = parseInt(date1[2]),
    day2 = parseInt(date2[2]);
  //通过年,月差计算月份差
  // let months = (year2 - year1) * 12 + (month2 - month1) + 1;
  let sameMonth1 = getMonthMaxMin(year1, month1);     // 获取当前月份有多少天
  let sameMonth2 = getMonthMaxMin(year2, month2);     // 获取当前月份有多少天

  if (month2 == month1) {
    // 如果开始时间不满月的时候
    if (day1 - day2 >= 1) {
      // 开始结束时间凑满一个月
      return {
        months: monthNum,
        laterDay: 0,
        frontDay: 0
      }
    } else if (day1 - day2 < 1) {
      // 开始时间多出的天数按照一月凑满
      return {
        months: monthNum + 1,
        laterDay: 0,
        frontDay: 0
      }
    }
  } else {
    if (day2 >= day1) {
      // 如果月份不相等
      return {
        months: monthNum + 1,
        laterDay: 0,
        frontDay: 0
      }
    } else {
      return {
        months: monthNum,
        laterDay: 0,
        frontDay: 0
      }
    }
  }


  // /**
  //  * 10.01--10.31  ==> 10.1 00:00:00 10.31 23:59:59
  //  * **/
  // if (day1 > 1) {
  //   // 那就说明开始的第一个月不满足一月
  //   if (sameMonth1 == day1) {
  //     dayTemp.frontDay = 1;
  //   } else {
  //     dayTemp.frontDay = sameMonth1 - day1 + 1;
  //   }
  // }

  // if (day2 < sameMonth2) {
  //   // 说明最后一个月也不足一个月
  //   if (sameMonth2 == day2) {
  //     dayTemp.laterDay = 1;
  //   } else {
  //     dayTemp.laterDay = sameMonth2 - day2;
  //   }
  // } else {
  //   if (day2 == 1) {
  //     dayTemp.laterDay = 0;
  //   }
  // }
  // dayTemp.months = months;
  // return dayTemp;
};

/**
 * 计算两个时间相差的月份
 * @param {开始时间} startDate 
 * @param {结束时间} endDate 
 */
export const getMonthDiff = (startDate, endDate) => {
  if (!startDate) {
    return;
  }
  if (!endDate) {
    return;
  }
  startDate = startDate.split('-');
  endDate = endDate.split('-');

  let totalMonths = (Number(endDate[0]) - Number(startDate[0])) * 12 + (Number(endDate[1]) - Number(startDate[1]));
  if (Number(endDate[2]) < Number(startDate[2])) {
    totalMonths--;
  }
  let fullMonths = Math.floor(totalMonths);
  return fullMonths;
  // startDate = new Date(startDate);
  // endDate = new Date(endDate);
  // let months;
  // months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  // months -= startDate.getMonth();
  // months += endDate.getMonth() + 1;
  // return months <= 0 ? 1 : months;
}

export const calculateYear = (date1, date2) => {
  let monthNum = datemonth(date1, date2);
  if (!date1) {
    date1 = date2 ? date2 : formatDateTime(new Date().getTime());
  }
  if (!date2) {
    date2 = date1 ? date1 : formatDateTime(new Date().getTime());
  }
  let dayTemp = {
    frontMonth: 0,
    laterMonth: 0,
    year: 0,
  };
  //用-分成数组
  date1 = date1.split("-");
  date2 = date2.split("-");
  //获取年,月数
  let year1 = parseInt(date1[0]),
    month1 = parseInt(date1[1]),
    year2 = parseInt(date2[0]),
    month2 = parseInt(date2[1]),
    day1 = parseInt(date1[2]),
    day2 = parseInt(date2[2]);

  // 如果两个相等就只有一年
  if (year1 == year2) {
    return {
      frontMonth: 0,
      laterMonth: 0,
      year: 1,
    };
  }
  // dayTemp.year = year2 - year1 +1; 自然年
  dayTemp.year = Math.ceil(monthNum / 12);
  if (month1 == month2 && day2 >= day1) {
    dayTemp.year += 1;
  }

  // 判断前面距离年底剩余了多少月份
  dayTemp.frontMonth = 12 - month1 + 1;

  // 判断最后的时间距离年底还剩下多少月份
  dayTemp.laterMonth = 12 - month2;

  return dayTemp;
};

export const getMonthMaxMin = (year, month) => {
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      return 31;
    case 2:
      if (year % 400 == 0 || (year % 4 == 0 && year % 100 != 0)) {
        return 29;
      } else {
        return 28;
      }
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
  }
};

/**
 * [formatTimeHms 时间转换时分秒]
 * @param  {[type]} inputTime [时间搓]
 * @return {[type]}           [description]
 */
export const formatTimeHms = (timeStamp, ishms) => {
  if (!timeStamp) {
    return "";
  }
  let date = new Date(timeStamp);
  //console.log(date); 结果为：Tue Apr 02 2019 07:49:23 GMT+0800 (中国标准时间)
  let seperator1 = "-";
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  if (hours >= 0 && hours <= 9) {
    hours = "0" + hours;
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = "0" + minutes;
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = "0" + seconds;
  }
  let currentdate = "";
  if (ishms) {
    currentdate =
      year +
      seperator1 +
      month +
      seperator1 +
      strDate +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
  } else {
    currentdate = year + seperator1 + month + seperator1 + strDate;
  }
  return currentdate;
};

/**
 * [missingNumber 计算整型数组中缺值]
 * @param  {[Array]} nums  [整型数组]
 * @return {[type]}      [description]
 */
export const missingNumber = (nums) => {
  nums.sort((a, b) => {
    return a - b;
  });
  let n = 1;
  if (nums[0] != 1) {
    return 1;
  }
  for (let i = 0; i < nums.length; i++) {
    if (nums[i] + 1 != nums[i + 1]) {
      n = nums[i] + 1;
      break;
    }
  }
  return n;
};

/**
 * [h 秒转为分秒]
 * @type {[type]}
 */
export const s_to_hs = (s) => {
  //计算分钟
  //算法：将秒数除以60，然后下舍入，既得到分钟数
  let h;
  h = Math.floor(s / 60);
  //计算秒
  //算法：取得秒%60的余数，既得到秒数
  s %= 60;
  //将变量转换为字符串
  h += "";
  s += "";
  //如果只有一位数，前面增加一个0
  h = h.length == 1 ? `0${h}` : h;
  s = s.length == 1 ? `0${s}` : s;
  return `${h}:${parseInt(s)}`;
};

/**
 * [swapItems 交换元素]
 * @param  {[type]} arr                  [交换的数组]
 * @param  {[type]} index1               [元素1]
 * @param  {[type]} index2               [元素2]
 * @return {[type]}        [返回交换的数组对象]
 */
export const swapItems = (arr, index1, index2) => {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0];
  return arr;
};
export const hashost = (url) => {
  if (!url) return;
  if (url.includes("http")) {
    return url;
  }
  return process.env.VUE_APP_IMG_URL + url;
};
export const dateChange = (date, form, startTime, endTime) => {
  form[startTime] = date ? date[0] : "";
  form[endTime] = date ? date[1] : "";
};
export const refresh = async () => {
  let roleIds = [];
  let res = await HttpPost("/refresh");
  if (res.data) {
    window.localStorage.setItem("exp", res.data.claims.exp * 1000);
    window.localStorage.setItem("token", res.data.token);
    if (res.data.role_id) {
      store.commit("setRoleIds", res.data.role_id.split(","));
      roleIds = store.state.roleIds;
    }
  }
  return roleIds;
};

export const hasRole = (id) => {
  // return true; // TODO: 暂时关闭权限
  let assids = store.state.roleIds;
  return assids.includes(id);
};

// 正确金额值
/*
第一个参数要要改变值原始对象
第二个要改变的参数键名
*/
const isMoney = function (item, value) {
  if (isNaN(item[value])) {
    return false;
  }
  if (item[value] < 0) {
    item[value] = 0;
  }
  let str = item[value].toString();
  let index = str.indexOf(".");
  let len = 0;
  if (index > -1) {
    len = str.substr(index, str.length - 1).length;
    if (len > 3) {
      item[value] = str.substr(0, index + 3);
      return false;
    }
  }
  return true;
};

/**
 * 小写金额转大写金额
 * */
export const dealBigMoney = (n) => {
  var fraction = ["角", "分"];
  var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  var unit = [
    ["元", "万", "亿"],
    ["", "拾", "佰", "仟"],
  ];
  var head = n < 0 ? "负" : "";
  n = Math.abs(n);
  var s = "";

  for (var i = 0; i < fraction.length; i++) {
    s += (
      digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
    ).replace(/零./, "");
  }
  s = s || "整";
  n = Math.floor(n);

  for (var i = 0; i < unit[0].length && n > 0; i++) {
    var p = "";
    for (var j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整")
  );
};

/**
 * 判断是否由http:// || https:// 开头
 **/
const isHttp = (value) => {
  const patt = /^(http:\/\/|https:\/\/){1}[\s\S]*/gi;
  return patt.test(value);
};
/**
 * 返回凭证
 * value:"['xxx','xxx']" or 'xxx'
 *
 * return []
 **/
export const getImg = (value) => {
  let reg = /^\[{1}[\s\S]*\]{1}$/gi;
  let isArrStr = reg.test(value);
  if (!isArrStr) {
    isArrStr = Array.isArray(value);
  }
  if (isArrStr) {
    let arr = [];
    if (Array.isArray(value)) {
      arr = value;
    } else {
      arr = JSON.parse(value) || [];
    }
    for (let i = 0; i < arr.length; i++) {
      if (!isHttp(arr[i])) {
        arr[i] = process.env.VUE_APP_IMG_URL + arr[i];
      }
    }
    return arr || [];
  } else if (typeof value === "string" && value.length > 0) {
    if (!isHttp(value)) {
      return [process.env.VUE_APP_IMG_URL + value];
    } else {
      return [value];
    }
  } else {
    return [];
  }
};

/**
 * 获取dateS n 个月后的日期 totalMonth>=0
 * nextDate  2015-12-16 + 83个月   =》 2022/11/16
 * 83/12~~ 6 年  12*6=72月  83-72=11月
 * */
export const initDateAdd = (dateS, totalMonth) => {
  totalMonth *= 1;
  let year = new Date(dateS).getFullYear(); //年
  let month = new Date(dateS).getMonth() + 1; //月
  let strDate = new Date(dateS).getDate(); //日
  let totalDay = new Date(year, month, 0).getDate(); //这个月天数

  let startY = year * 1;
  let startM = month * 1;

  let cYear = (totalMonth / 12).toString().replace(/(\d+)(\.\d+)?/gi, "$1") * 1;
  if (totalMonth - 12 * cYear + month > 12) {
    /**
     * 比如 dateS：2015-12-16  totalMonth：83
     * 83/12~~ 6 年  12*6=72月  83-72=11月
     * 2015+6 = 2021 但是dateS的月份 + （83-72=11月）>12 代表 又在原来基础上多了1年
     * */
    startM = totalMonth - 12 * cYear + month - 12;
    cYear += 1;
  } else {
    startM = totalMonth - 12 * cYear + month;
  }
  startY += cYear;
  if (strDate >= 28) {
    let a = totalDay - strDate;
    let b = new Date(startY, startM, 0).getDate(); //月天数
    if (strDate < b) {
      if (a == 0) {
        return formatTimeHms(new Date(startY, startM - 1, b));
      }
      return formatTimeHms(new Date(startY, startM - 1, strDate));
    }
    if (strDate >= b) {
      if (startM == 2) {
        return formatTimeHms(new Date(startY, startM - 1, b));
      } else {
        if (a == 0) {
          return formatTimeHms(new Date(startY, startM - 1, b));
        }
        return formatTimeHms(new Date(startY, startM - 1, strDate));
      }
    }
  } else {
    return formatTimeHms(new Date(startY, startM - 1, strDate));
  }
};
/**
 *
 * @param {*} dateStr 可被date 解析时间字符串
 * @param {*} days  加几天 默认1
 * @returns  转换后字符串
 */
export const dateDayAdd = (dateStr, days = 1) => {
  let dateTime = new Date(dateStr);
  dateTime = dateTime.setDate(dateTime.getDate() + days);
  dateTime = new Date(dateTime);
  const result = formatDateTime(dateTime);
  return result;
};
/**
 *
 * @param {*} id 身份证id 身份证号(15位、18位数字)，最后一位是校验位，可能为数字或字符X
 * @returns {Boolean} 校验通过 true  , 失败false
 */

export const verifyId = (id) => {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  reg.test(id);
  return reg.test(id);
};
/**
 *
 * @param {*} id 手机号 //不含短号
 * @param {Object}options: {checkSpecSort:false} 是否校验短号
 * @returns
 */
export const verifyPhone = (id, options = { checkSpecSort: false }) => {
  let specialPlane = /^\d{3}-\d{8}$|^\d{4}-\d{7}$|^\d{4}-\d{8}$/;
  const reg =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  reg.test(id);
  const checkSpecSort = options.checkSpecSort ? specialPlane.test(id) : true
  return reg.test(id) || checkSpecSort;
};
