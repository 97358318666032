var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mybutton",class:[
    { disabled:_vm.disabled||_vm.loading },
    _vm.type,
    _vm.left ? 'mybtn-left' : '',
    _vm.right ? 'mybtn-right' : '',
    _vm.$slots.preImage ? 'paddings' : 'padding',
  ],on:{"click":_vm.debounce}},[_vm._t("preImage"),(_vm.loading)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),_vm._t("default",function(){return [_vm._v(_vm._s(_vm.title))]}),_vm._t("suffImage")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }