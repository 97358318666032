import { Loading } from "element-ui";
import axios from "axios";
import { Notification } from "element-ui";

const request = (data = {}) => {
  let loadinstance = null;
  let timer = setTimeout(() => {
    loadinstance = Loading.service({
      background: "rgba(0,0,0,.6)",
      text: "加载中...",
    });
  }, 800);
  return axios(data).then((res) => {
    clearTimeout(timer);
    if (loadinstance) {
      loadinstance.close();
    }

    if (res.status >= 400) {
      throw new Error("请求错误");
    } else {
      if (data.responseType == "arraybuffer") {
        return res.data;
      }
      if (data.responseType == "blob") {
        return res.data;
      }
      if (res.data.code == 0) {
        return res.data;
      }
      if (
        res.data.code == 7 &&
        (res.data.msg == "无效token" || res.data.msg == "确少必要参数token")
      ) {
        window.localStorage.removeItem("userInfo");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("exp");
        window.location.href = "/#/login";
      }
      if (res.data.code == -1 && res.data.msg == "无效登陆！") {
        window.localStorage.removeItem("userInfo");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("exp");
        window.location.href = "/#/login";
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      Notification({
        title: "错误",
        message: res.data.msg || "未知错误",
        type: "error",
      });
      throw new Error(res);
    }
  })
    .catch((error) => {
      throw new Error(error);
    })
    .finally(() => {
      clearTimeout(timer);
      if (loadinstance) {
        loadinstance.close();
      }
    });
};

export const HttpGet = async (path = "", data = {}, type = {}, head = {}) => {
  const token = window.localStorage.getItem("token");
  const userInfo = window.localStorage.getItem("userInfo") ? JSON.parse(window.localStorage.getItem("userInfo")) : {};
  let headers = {
    "Content-Type": "application/json",
    Authorization: token,
    market_id: userInfo.market_id,
    ...head,
  };
  let url = "";
  if (["/login"].indexOf(path) != -1) {
    url = process.env.VUE_APP_LOGIN;
  } else if (
    [
      "/user/delete",
      "/user/resetPwd",
      "/user/add",
      "/user/edit",
      "/management/list",
      "/user/setProperty",
      "/user/list",
      "/management/list",
      "/management/delete",
      "/management/add",
      "/management/edit",
    ].indexOf(path) != -1
  ) {
    url = process.env.VUE_APP_USER_INFO_URL;
  } else if (['/financial/merchant', '/financial/feeNames', '/financial/merchantExport', '/financial/stall', '/financial/stallExport'].indexOf(path) != -1) {
    url = process.env.VUE_APP_FINANCIAL;
  } else {
    url = process.env.VUE_APP_REQUIRE_URL;
  }
  let succeed = await request({
    method: "GET",
    url: url + path,
    headers,
    params: data,
    responseType: type.responseType || "json",
  });
  return succeed;
};

export const HttpPost = async (path = "", data = {}, type = {}, head = {}) => {
  const token = window.localStorage.getItem("token");
  const userInfo = window.localStorage.getItem("userInfo") ? JSON.parse(window.localStorage.getItem("userInfo")) : {};
  let headers = {
    "Content-Type": "application/json",
    Authorization: token,
    market_id: userInfo.market_id,
    ...head,
  };
  let url = "";
  if (["/login"].indexOf(path) != -1) {
    url = process.env.VUE_APP_LOGIN;
  } else if (path == "/refresh") {
    url = process.env.VUE_APP_LOGIN;
  } else if (
    [
      "/user/delete",
      "/user/resetPwd",
      "/user/add",
      "/user/edit",
      "/management/list",
      "/user/setProperty",
      "/user/list",
      "/management/list",
      "/management/delete",
      "/management/add",
      "/management/edit",
      "/user/export",
      "/management/export",
    ].indexOf(path) != -1
  ) {
    url = process.env.VUE_APP_USER_INFO_URL;
  } else if (['/financial/merchant', '/financial/feeNames', '/financial/merchantExport', '/financial/stall', '/financial/stallExport'].indexOf(path) != -1) {
    url = process.env.VUE_APP_FINANCIAL;
  } else {
    url = process.env.VUE_APP_REQUIRE_URL;
  }
  // if (path == '/report/index') {
  //   url = 'http://192.168.1.155:8200'
  // }
  let succeed = await request({
    method: "POST",
    url: url + path,
    headers,
    data,
    responseType: type.responseType || "json",
  });
  return succeed;
};
export const dailyGet = async (path = "", data = {}, type = {}, head = {}) => {
  const token = window.localStorage.getItem("token");
  const userInfo = window.localStorage.getItem("userInfo") ? JSON.parse(window.localStorage.getItem("userInfo")) : {};
  let headers = {
    "Content-Type": "application/json",
    Authorization: token,
    market_id: userInfo.market_id,
    ...head,
  };

  let succeed = await request({
    method: "GET",
    url: process.env.VUE_APP_DAILY_URL + path,
    headers,
    params: data,
    responseType: type.responseType || "json",
  });
  return succeed;
};

export const dailyPost = async (path = "", data = {}, type = {}, head = {}) => {
  const token = window.localStorage.getItem("token");
  const userInfo = window.localStorage.getItem("userInfo") ? JSON.parse(window.localStorage.getItem("userInfo")) : {};
  let headers = {
    "Content-Type": "application/json",
    Authorization: token,
    market_id: userInfo.market_id,
    ...head,
  };

  let succeed = await request({
    method: "POST",
    url: process.env.VUE_APP_DAILY_URL + path,
    headers,
    data,
    responseType: type.responseType || "json",
  });
  return succeed;
};
