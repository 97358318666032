<template>
  <div
    class="mybutton"
    :class="[
      { disabled:disabled||loading },
      type,
      left ? 'mybtn-left' : '',
      right ? 'mybtn-right' : '',
      $slots.preImage ? 'paddings' : 'padding',
    ]"
    @click="debounce"
  >
    <slot name="preImage"></slot>
       <i v-if="loading" class="el-icon-loading"></i>
    <slot>{{ title }}</slot>
    <slot name="suffImage"></slot>
  </div>
</template>

<script>
//  @click="!disabled && emit()"
import {debounce} from '../debounce';
export default {
  name: "myButton",
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
      default: "按钮",
    },
    type: {
      type: String,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accessId: {
      type: [String, Number],
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: Boolean,
      default: true,
    },
     loading:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      timer: 'ss',
    };
  },
  created() {},
  methods: {
    debounce: debounce(function(){
      // this.emit()
       if (this.disabled || this.loading) return false;
      if (this.accessId) {
        let superAdmin = JSON.parse(window.localStorage.getItem("userInfo"));
        if (superAdmin.is_manage == 1) {
          this.$emit("click");
        } else {
          if (this.$common.hasRole(this.accessId)) {
            this.$emit("click");
          } else {
            this.$message({ message: "暂无权限!", type: "error" });
          }
        }
      } else {
        this.$emit("click");
      }
      // if(this.disabled) return false;
      //  this.$emit("click");
    }),
    emit() {
      
    },
  },
};
</script>

<style lang="scss" scoped>
.mybutton {
  min-width: 66px;
  height: 32px;
  background: #ffffff;
  // border: .5px solid #dedede;
  box-shadow: 1px 1px 1px .5px #dedede,-0.2px -0.2px 1px  #dedede;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 0;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  user-select: none;
  white-space: none;
  /deep/ img {
    margin: 0 5px 0 10px;
    vertical-align: middle;
    width: 18px;
    height: 18px;
  }
  & > * {
    pointer-events: none;
  }

  .mybtn {
    flex: 1;
    text-align: center;
    user-select: none;
  }
  /deep/ img {
    width: 22px;
    height: 22px;
    pointer-events: none;
  }
  &:hover {
    background: #f9f9f9;
    color: #3d7eff;
    .mybtn {
      color: #3d7eff;
    }
  }
}
.padding {
  padding: 0 15px;
  box-sizing: border-box;
}
.paddings {
  padding-right: 15px;
}
.default {
  color: #000;
}

.primary {
  color: #3d7eff;
}
.error {
  color: red;
}
.disabled {
  cursor: not-allowed;
  background: #f4f4f4;
}
.mybtn-left {
  margin-left: 15px;
}
.mybtn-right {
  margin-right: 15px;
}
</style>
