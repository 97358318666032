import store from "@/store";
import router from "../router/index";

let allRoute = [];
let treeRootData = [];
// let createInit = () => {
/*
 * 项目路由组
 * */
let routeArr = [
  {
    name: "welcome",
    path: "/welcome",
    meta: {
      title: "欢迎",
    },
    component: () => import("../views/welcome.vue"),
  },
  {
    name: "datapreview",
    path: "/datapreview",
    meta: {
      title: "物业概览",
      roleId: 45820,
    },
    component: () =>
      import(
        /* webpackChunkName: "datapreview" */ "../views/datapreview/index"
      ),
  },
  {
    name: "opercenter",
    path: "/opercenter",
    meta: {
      title: "操作台",
      roleId: 11754,
    },
    component: () =>
      import(
        /* webpackChunkName: "opercenter" */ "../views/opercenter/opercenter"
      ),
  },
  {
    name: "merchants",
    path: "/merchants",
    meta: {
      title: "商户管理",
      roleId: 41011,
    },
    component: () =>
      import(
        /* webpackChunkName: "merchants" */ "../views/merchants/merchants"
      ),
  },
  {
    name: "merchants_temporary",
    path: "/merchants_temporary",
    meta: {
      title: "临时商户",
      roleId: 41012,
    },
    component: () =>
      import(
        /* webpackChunkName: "merchants" */ "../views/merchants/merchants_temporary"
      ),
  },
  {
    name: "merchantdetail",
    path: "/merchants/detail",
    meta: {
      title: "商户详情",
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantdetail" */ "../views/merchants/detail"
      ),
  },
  ,
  {
    name: "merchantsTemporaryDetail",
    path: "/merchants_temporary_detail",
    meta: {
      title: "商户详情",
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantdetail" */ "../views/merchants/merchants_temporary_detail"
      ),
  },
  {
    name: "contract",
    path: "/contract",
    meta: {
      title: "合同管理",
      roleId: 71477,
    },
    component: () =>
      import(
        /* webpackChunkName: "merchantdetail" */ "../views/contract/contract"
      ),
    children: [
      {
        name: "contractpreview",
        path: "contractpreview",
        meta: {
          title: "合同概览",
          roleId: 80035,
        },
        component: () =>
          import(
            /* webpackChunkName: "contractpreview" */ "../views/contract/contractpreview/index"
          ),
      },
      {
        name: "addedcontract",
        path: "addedcontract",
        meta: {
          title: "新增合同",
          roleId: 92169,
        },
        component: () =>
          import(
            /* webpackChunkName: "contractpreview" */ "../views/contract/list/addcontract"
          ),
      },
      {
        name: "editcontract",
        path: "editcontract",
        meta: {
          title: "编辑合同",
        },
        component: () =>
          import(
            /* webpackChunkName: "contractpreview" */ "../views/contract/list/editcontract"
          ),
      },
      {
        name: "approval",
        path: "approval",
        meta: {
          title: "审批",
        },
        component: () =>
          import(
            /* webpackChunkName: "approval" */ "../views/contract/list/approval"
          ),
      },
      {
        name: "contractlist",
        path: "contractlist",
        meta: {
          title: "合同列表",
          roleId: 82936,
        },
        component: () =>
          import(
            /* webpackChunkName: "contractlist" */ "../views/contract/list/list"
          ),
      },
      // {
      //   name: "attachlist",
      //   path: "attachlist",
      //   meta: {
      //     title: "附属合同列表",
      //     roleId: 51000,
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "contractlist" */ "../views/contract/attach/index.vue"
      //     ),
      // },
      {
        name: "terminationRecord",
        path: "terminationRecord",
        meta: {
          title: "终止合同记录",
          roleId: 95539,
        },
        component: () =>
          import(
            /* webpackChunkName: "terminationRecord" */ "../views/contract/list/terminationRecord"
          ),
      },
      {
        name: "batchcontract",
        path: "batchcontract",
        meta: {
          title: "批量合同导入",
          roleId: 82937,
        },
        component: () =>
          import(
            /* webpackChunkName: "contractlist" */ "../views/contract/batchcontract"
          ),
      },
      {
        name: "contractSetting",
        path: "contractSetting",
        children: [
          {
            name: "contractsSetting",
            path: "contractsSetting",
            meta: {
              title: "合同设置",
              roleId: 24641,
            },
            component: () =>
              import(
                /* webpackChunkName: "contractsSetting" */ "../views/contract/setting/contractsetting"
              ),
          },
          {
            name: "contractfnsetting",
            path: "contractfnsetting",
            meta: {
              title: "功能设置",
              roleId: 93443,
            },
            component: () =>
              import(
                /* webpackChunkName: "contractfnsetting" */ "../views/contract/setting/fnsetting"
              ),
          },
        ],
        redirect: "/contract/contractSetting/contractsSetting",
        component: () =>
          import(
            /* webpackChunkName: "contractSetting" */ "../views/contract/setting/index"
          ),
      },
      {
        name: "contractdetail",
        path: "contractdetail",
        meta: {
          title: "合同详情",
          roleId: 34348,
        },
        component: () =>
          import(
            /* webpackChunkName: "contractdetail" */ "../views/contract/detail/detail"
          ),
      },
      {
        name: "contracthistory",
        path: "contracthistory",
        meta: {
          title: "历史合同",
          roleId: 81957,
        },
        component: () =>
          import(
            /* webpackChunkName: "contracthistory" */ "../views/contract/history/index"
          ),
      },
      {
        name: "addedcontracts",
        path: "addedcontract",
        meta: {
          title: "新增商户合同",
        },
        component: () =>
          import(
            /* webpackChunkName: "addedcontract" */ "../views/contract/list/addcontract"
          ),
      },
      {
        name: "batchcontractDetail",
        path: "batchcontractDetail",
        meta: {
          title: "数据维护/审核",
          roleId: 82939,
          othId: 82940,
        },
        component: () =>
          import(
            /* webpackChunkName: "batchcontractDetail" */ "../views/contract/batchcontractDetail"
          ),
      },
    ],
  },
  // 商铺————————————————————————————————————————————————————————————————
  {
    name: "shanghugl",
    path: "/merchant",
    meta: {
      title: "商户管理",
    },
    component: () =>
      import(/* webpackChunkName: "merchant" */ "../views/merchant/index"),
    children: [
      {
        name: "merchantpreview",
        path: "merchantpreview",
        meta: {
          title: "商铺出租概览",
          roleId: 35398,
        },
        component: () =>
          import(
            /* webpackChunkName: "merchantpreview" */ "../views/merchant/merchantpreview/index"
          ),
      },
      {
        name: "merchantlist",
        path: "merchantlist",
        meta: {
          title: "商铺列表",
          roleId: 43269,
        },
        component: () =>
          import(
            /* webpackChunkName: "merchantlist" */ "../views/merchant/merchantlist/index"
          ),
      },
      {
        name: "merchanttype",
        path: "merchanttype",
        meta: {
          title: "商铺类型",
          roleId: 69689,
        },
        component: () =>
          import(
            /* webpackChunkName: "merchanttype" */ "../views/merchant/merchanttype/index"
          ),
      },
      {
        name: "merchantsetting",
        path: "merchantsetting",
        meta: {
          title: "商铺设置",
          roleId: 13776,
        },
        component: () =>
          import(
            /* webpackChunkName: "merchantsetting" */ "../views/merchant/merchantsetting/index"
          ),
      },
      {
        name: "building",
        path: "building",
        meta: {
          title: "楼栋",
          roleId: 69690,
        },
        component: () =>
          import(
            /* webpackChunkName: "building" */ "../views/merchant/building/index"
          ),
      },
    ],
  },
  // 收费————————————————————————————————————————————————————————————————————————
  {
    name: "chargeIndex",
    path: "/charge",
    component: () =>
      import(/* webpackChunkName: "chargepreview" */ "../views/charge/index"),
    children: [
      {
        name: "chargepreview",
        path: "chargepreview",
        meta: {
          title: "收费概览",
          roleId: 11531,
        },
        component: () =>
          import(
            /* webpackChunkName: "chargepreview" */ "../views/charge/chargepreview/index"
          ),
      },
      {
        name: "merchantbill",
        path: "merchantbill",
        meta: {
          title: "商户账单",
          roleId: 93115,
        },
        redirect: "/charge/merchantbill/basebill",
        children: [
          {
            name: "basebill",
            path: "basebill",
            meta: {
              title: "基本账单",
              roleId: 44209,
            },
            component: () =>
              import(
                /* webpackChunkName: "basebill" */ "../views/charge/merchantbill/base"
              ),
          },
          {
            name: "basebilldetail",
            path: "basebilldetail",
            meta: {
              title: "基本账单详情",
              roleId: 77692,
            },
            component: () =>
              import(
                /* webpackChunkName: "basebilldetail" */ "../views/charge/merchantbill/basedetail"
              ),
          },
          {
            name: "daybill",
            path: "daybill",
            meta: {
              title: "日常账单",
              roleId: 25853,
            },
            component: () =>
              import(
                /* webpackChunkName: "daybill" */ "../views/charge/merchantbill/daybill"
              ),
          },
          {
            name: "daybilldetail",
            path: "daybilldetail",
            meta: {
              title: "日常账单详细",
              roleId: 87177,
            },
            component: () =>
              import(
                /* webpackChunkName: "daybilldetail" */ "../views/charge/merchantbill/daybilldetail"
              ),
          },
          {
            name: "chargeoff",
            path: "chargeoff",
            meta: {
              title: "出账记录",
              roleId: 84558,
            },
            component: () =>
              import(
                /* webpackChunkName: "chargeoff" */ "../views/charge/merchantbill/chargeoff"
              ),
          },
        ],
        component: () =>
          import(
            /* webpackChunkName: "merchantbill" */ "../views/charge/merchantbill/index"
          ),
      },
      {
        name: "chargesetting",
        path: "chargesetting",
        meta: {
          title: "收费设置",
        },
        redirect: "/charge/chargesetting/chargeset",
        children: [
          {
            name: "chargeset",
            path: "chargeset",
            meta: {
              title: "收费设置",
              roleId: 89587,
            },
            component: () =>
              import(
                /* webpackChunkName: "chargeset" */ "../views/charge/chargesetting/chargeset"
              ),
          },
          {
            name: "daychargeset",
            path: "daychargeset",
            meta: {
              title: "日常收费设置",
              roleId: 22596,
            },
            component: () =>
              import(
                /* webpackChunkName: "daychargeset" */ "../views/charge/chargesetting/daychargeset"
              ),
          },
          {
            name: "contractchargeset",
            path: "contractchargeset",
            meta: {
              title: "合同收费设置",
              roleId: 57594,
            },
            component: () =>
              import(
                /* webpackChunkName: "contractchargeset" */ "../views/charge/chargesetting/contractchargeset"
              ),
          },
          {
            name: "assistantContractChargeset",
            path: "assistantContractChargeset",
            meta: {
              title: "副合同收费设置",
              roleId: 16000,
            },
            component: () =>
              import(
                /* webpackChunkName: "assistantContractChargeset" */ "../views/charge/chargesetting/assistantContractChargeset"
              ),
          },
        ],
        component: () =>
          import(
            /* webpackChunkName: "chargesetting" */ "../views/charge/chargesetting/index"
          ),
      },
      {
        name: "jmsetting",
        path: "jmsetting",
        meta: {
          title: "基础费用减免",
          roleId: 55628,
        },
        component: () =>
          import(
            /* webpackChunkName: "jmsetting" */ "../views/charge/chargesetting/chargesetchild/jmsetting.vue"
          ),
      },
      {
        name: "specialOffer",
        path: "specialOffer",
        meta: {
          title: "优惠活动",
          roleId: 4540,
        },
        component: () =>
          import(
            /* webpackChunkName: "specialOffer" */ "../views/charge/chargesetting/specialOffer.vue"
          ),
      },
      {
        name: "chargevoucher",
        path: "chargevoucher",
        meta: {
          title: "收费凭证",
          roleId: 34523,
        },
        component: () =>
          import(
            /* webpackChunkName: "chargevoucher" */ "../views/charge/chargevoucher/index"
          ),
      },
      {
        name: "chargeoffline",
        path: "chargeoffline",
        meta: {
          title: "收费记录",
          roleId: 51706,
        },
        component: () =>
          import(
            /* webpackChunkName: "chargeoffline" */ "../views/charge/chargeoffline/index"
          ),
      },
      {
        name: "transfer",
        path: "transfer",
        meta: {
          title: "转移记录",
          roleId: 11533,
        },
        component: () =>
          import(
            /* webpackChunkName: "transfer" */ "../views/charge/transfer/index"
          ),
      },
      {
        name: "addCharge",
        path: "addCharge",
        meta: {
          title: "新建收费",
          roleId: 2761,
        },
        component: () =>
          import(
            /* webpackChunkName: "addCharge" */ "../views/charge/chargeoffline/addCharge"
          ),
      },
      {
        name: "intention",
        path: "intention",
        meta: {
          title: "意向金",
          roleId: 16542,
        },
        component: () =>
          import(
            /* webpackChunkName: "intention" */ "../views/charge/intention/index"
          ),
      },
    ],
  },
  // 投诉_________________________________________
  {
    name: "complain",
    path: "/complain",
    meta: {
      title: "投诉",
    },
    component: () =>
      import(
        /* webpackChunkName: "complainpreview" */ "../views/complain/index"
      ),
    children: [
      {
        name: "complainpreview",
        path: "complainpreview",
        meta: {
          title: "投诉概览",
          roleId: 44428,
        },
        component: () =>
          import(
            /* webpackChunkName: "complainpreview" */ "../views/complain/complainpreview/index"
          ),
      },
      {
        name: "complainlist",
        path: "complainlist",
        meta: {
          title: "投诉列表",
          roleId: 81147,
        },
        component: () =>
          import(
            /* webpackChunkName: "complainlist" */ "../views/complain/list/index"
          ),
        redirect: "/complain/complainlist/pending",
        children: [
          {
            path: "pending",
            name: "pending",
            meta: {
              title: "待处理",
              roleId: 16371,
            },
            component: () =>
              import(
                /* webpackChunkName: "pending" */ "../views/complain/list/pending"
              ),
          },
          {
            path: "processed",
            name: "processed",
            meta: {
              title: "已处理",
              roleId: 75312,
            },
            component: () =>
              import(
                /* webpackChunkName: "processed" */ "../views/complain/list/processed"
              ),
          },
          {
            name: "detail",
            path: "detail",
            meta: {
              title: "详情",
              roleId: 33417,
            },
            component: () =>
              import(
                /* webpackChunkName: "detail" */ "../views/complain/list/detail"
              ),
          },
        ],
      },
    ],
  },
  // 智能巡检——————————————————————————————————————————————————————————————————————————
  {
    name: "polling",
    path: "/polling",
    meta: {
      title: "智能巡检",
    },
    component: () =>
      import(
        /* webpackChunkName: "pollingschedule" */ "../views/polling/home.vue"
      ),
    children: [
      {
        name: "pollingsupervise",
        path: "pollingsupervise",
        meta: {
          title: "巡检监管",
          roleId: 84720,
        },
        component: () =>
          import(
            /* webpackChunkName: "pollingschedule" */ "../views/polling/index"
          ),
      },
      {
        name: "pollingschedule",
        path: "pollingschedule",
        meta: {
          title: "巡检计划表",
          roleId: 84722,
        },
        component: () =>
          import(
            /* webpackChunkName: "pollingschedule" */ "../views/polling/pollingschedule/index"
          ),
      },
      {
        name: "abnormal",
        path: "abnormal",
        meta: {
          title: "巡检情况",
          roleId: 847226,
        },
        component: () =>
          import(
            /* webpackChunkName: "abnormal" */ "../views/polling/abnormal/index"
          ),
      },
      {
        name: "abnormalDetail",
        path: "abnormalDetail",
        meta: {
          title: "巡检情况详情",
          roleId: 847229,
        },
        component: () =>
          import(
            /* webpackChunkName: "abnormalDetail" */ "../views/polling/abnormal/abnormalDetail"
          ),
      },
      {
        name: "pollinglog",
        path: "pollinglog",
        meta: {
          title: "巡检日志",
          roleId: 8472230,
        },
        component: () =>
          import(
            /* webpackChunkName: "pollinglog" */ "../views/polling/pollinglog/index"
          ),
      },
      {
        name: "pollingsetting",
        path: "pollingsetting",
        meta: {
          title: "巡检设置",
          roleId: 847250,
        },
        component: () =>
          import(
            /* webpackChunkName: "pollingsetting" */ "../views/polling/pollingsetting/pointCode"
          ),
      },
    ],
  },
  //报修管理
  {
    name: "repair",
    path: "/repair",
    meta: { title: "报修管理", roleId: 2424 },
    component: () =>
      import(/* webpackChunkName:"repair"*/ "../views/repair/index.vue"),
    children: [
      {
        name: "repairlist",
        meta: {
          title: "报修列表",
          roleId: 2425,
        },
        path: "list",
        component: () =>
          import(
            /* webpackChunkName: "repairlist"*/ "../views/repair/list.vue"
          ),
      },
      {
        name: "record",
        meta: {
          title: "维修记录",
          roleId: 2426,
        },
        path: "record",
        component: () =>
          import(
            /* webpackChunkName: "repairrecord"*/ "../views/repair/record.vue"
          ),
      },
    ],
  },
  //财务管理---------------------------------------------------
  {
    name: "finance",
    path: "/finance",
    meta: {
      title: "财务管理",
    },
    component: () =>
      import(/* webpackChunkName: "finance" */ "../views/finance/index"),
    children: [
      {
        name: "financepreview",
        path: "financepreview",
        meta: {
          title: "财务概览",
          roleId: 55053,
        },
        component: () =>
          import(
            /* webpackChunkName: "financepreview" */ "../views/finance/financepreview/finacepreview"
          ),
      },
      {
        path: "dataReport",
        name: "dataReport",
        meta: {
          title: "数据报表",
          roleId: 55052,
        },
        component: () =>
          import(
            /* webpackChunkName: "dataReport" */ "../views/finance/dataReport.vue"
          ),
      },
      {
        path: "financialStatement",
        name: "financialStatement",
        meta: {
          title: "财务报表",
          roleId: 55055,
        },
        redirect: "/finance/financialStatement/merchantList",
        component: () => import(/* webpackChunkName: "financialStatement" */ "../views/finance/financialStatement/financialStatement.vue"),
        children: [
          {
            name: "merchantList",
            path: "merchantList",
            meta: {
              title: "商户财务报表",
              roleId: 52488,
            },
            component: () => import(/* webpackChunkName: "merchantList" */ "../views/finance/financialStatement/merchantList.vue")
          },
          {
            name: "shopList",
            path: "shopList",
            meta: {
              title: "商铺财务报表",
              roleId: 52488,
            },
            component: () => import(/* webpackChunkName: "shopList" */ "../views/finance/financialStatement/shopList.vue")
          }
        ]
      },
      {
        name: "accountcheck",
        path: "accountcheck",
        meta: {
          title: "对账管理",
          roleId: 61182,
        },
        children: [
          {
            name: "lineBill",
            path: "lineBill",
            meta: {
              title: "线上账单",
              roleId: 52488,
            },
            component: () =>
              import(
                /* webpackChunkName: "lineBill" */ "../views/finance/accountcheck/lineBill"
              ),
          },
          {
            name: "cashBill",
            path: "cashBill",
            meta: {
              title: "现金账单",
              roleId: 53017,
            },
            component: () =>
              import(
                /* webpackChunkName: "cashBill" */ "../views/finance/accountcheck/cashbill"
              ),
          },
          {
            name: "historyRecord",
            path: "historyRecord",
            meta: {
              title: "线上历史核销记录",
              roleId: 31221,
            },
            component: () =>
              import(
                /* webpackChunkName: "historyRecord" */ "../views/finance/accountcheck/historyRecord"
              ),
          },
          {
            name: "cashHistory",
            path: "cashHistory",
            meta: {
              title: "现金历史核销记录",
              roleId: 42972,
            },
            component: () =>
              import(
                /* webpackChunkName: "cashHistory" */ "../views/finance/accountcheck/cashHistory"
              ),
          },
        ],
        redirect: "/finance/accountcheck/lineBill",
        component: () =>
          import(
            /* webpackChunkName: "accountcheck" */ "../views/finance/accountcheck/index"
          ),
      },
      {
        name: "refund",
        path: "refund",
        meta: {
          title: "退款管理",
          roleId: 85139,
        },
        component: () =>
          import(
            /* webpackChunkName: "refund" */ "../views/finance/refund/refund"
          ),
      },
      {
        name: "refundhis",
        path: "refundhis",
        meta: {
          title: "历史退款记录",
          roleId: 25826,
        },
        component: () =>
          import(
            /* webpackChunkName: "refundhis" */ "../views/finance/refund/refundhis"
          ),
      },
    ],
  },
  //智能电器仪表管理 TODO权限未处理
  {
    name: "meter",
    path: "/meter",
    meta: {
      title: "智能电表管理",
    },
    redirect: "/meter/rechargeRecord",

    component: () =>
      import(
        /* webpackChunkName: "meterManageIndex" */ "../views/meterManage/index.vue"
      ),
    children: [
      {
        name: "meterManage",
        path: "meterManage",
        meta: {
          title: "智能电表管理",
          roleId: 70394,
        },
        component: () =>
          import(/* webpackChunkName: "meterManage" */ "../views/meterManage/meterManageList/meterManage.vue"),
      },
      {
        name: "rechargeRecord",
        path: "rechargeRecord",
        meta: {
          title: "充值记录",
          roleId: 16024,
        },
        component: () =>
          import(
            /* webpackChunkName: "rechargeRecord" */ "../views/meterManage/record/rechargeRecord.vue"
          ),
      },
      {
        name: "electricTotal",
        path: "electricTotal",
        meta: {
          title: "用电统计",
          roleId: 16024,
        },
        component: () =>
          import(
            /* webpackChunkName: "rechargeRecord" */ "../views/meterManage/record/electricTotal.vue"
          ),
      },
      {
        name: "merchantElectricTotal",
        path: "merchantElectricTotal",
        meta: {
          title: "商户用电统计",
          roleId: 16024,
        },
        component: () =>
          import(
            /* webpackChunkName: "rechargeRecord" */ "../views/meterManage/record/merchantElectricTotal.vue"
          ),
      },
      {
        name: "clearRecord",
        path: "clearRecord",
        meta: {
          title: "清零记录",
          roleId: 16024,
        },
        component: () =>
          import(
            /* webpackChunkName: "rechargeRecord" */ "../views/meterManage/record/clearRecord.vue"
          ),
      },
    ],
  },
  {
    name: "meterWater",
    path: "/meterWater",
    meta: {
      title: "智能水表管理",
    },
    component: () =>
      import(
        /* webpackChunkName: "meterManageIndex" */ "../views/meterManage/water/index.vue"
      ),
    redirect: "/meterWater/rechargeRecord",

    children: [
      {
        name: "waterMeterManage",
        path: "meterManage",
        meta: {
          title: "智能水表管理",
          roleId: 70394,
        },
        component: () =>
          import(
            /* webpackChunkName: "meterManage" */ "../views/meterManage/water/meterMangeList/meterManage.vue"
          ),
      },
      {
        name: "waterRechargeRecord",
        path: "rechargeRecord",
        meta: {
          title: "充值记录",
          roleId: 16024,
        },
        component: () =>
          import(
            /* webpackChunkName: "rechargeRecord" */ "../views/meterManage/water/rechargeRecord/rechargeRecord.vue"
          ),
      },
      {
        name: "waterTotal",
        path: "waterTotal",
        meta: {
          title: "用水统计",
          roleId: 16024,
        },
        component: () =>
          import(
            /* webpackChunkName: "rechargeRecord" */ "../views/meterManage/water/waterTotal/waterTotal.vue"
          ),
      },
      {
        name: "merchantWaterTotal",
        path: "merchantWaterTotal",
        meta: {
          title: "商户用水统计",
          roleId: 16024,
        },
        component: () =>
          import(
            /* webpackChunkName: "rechargeRecord" */ "../views/meterManage/water/merchantWaterTotal/merchantWaterTotal.vue"
          ),
      },
      {
        name: "waterClearRecord",
        path: "clearRecord",
        meta: {
          title: "清零记录",
          roleId: 16024,
        },
        component: () =>
          import(
            /* webpackChunkName: "rechargeRecord" */ "../views/meterManage/water/clearRecord/clearRecord.vue"
          ),
      },
    ],
  },
  {
    name: "devMan",
    path: "/devMAN",
    meta: {
      title: "设备管理",
      roleId: 93800,
    },
    component: () =>
      import(/* webpackChunkName: "operlog" */ "../views/devMan/index.vue"),
  },
  {
    name: "operlog",
    path: "/operlog",
    meta: {
      title: "操作日志",
      roleId: 93664,
    },
    component: () =>
      import(/* webpackChunkName: "operlog" */ "../views/operlog/index.vue"),
  },

  {
    name: "opermanSetup",
    path: "/opermanSetup",
    meta: {
      title: "操作员设置",
    },
    redirect: "/opermanSetup/manage",
    component: () =>
      import(
        /* webpackChunkName: "opermanSetup" */ "../views/operman/opermanSetup.vue"
      ),
    children: [
      {
        name: "manage",
        path: "manage",
        meta: {
          title: "操作员管理",
          roleId: 70394,
        },
        component: () =>
          import(/* webpackChunkName: "manage" */ "../views/operman/index.vue"),
      },
      {
        name: "roleManage",
        path: "roleManage",
        meta: {
          title: "角色管理",
          roleId: 16024,
        },
        component: () =>
          import(
            /* webpackChunkName: "roleManage" */ "../views/operman/roleManage.vue"
          ),
      },
    ],
  },
  {
    name: "system",
    path: "/system",
    meta: {
      title: "系统设置",
      roleId: 40437,
    },
    redirect: "/system/account",
    component: () =>
      import(/* webpackChunkName: "system" */ "../views/system/index.vue"),
    children: [
      {
        name: "account",
        path: "account",
        meta: {
          title: "收款账号设置",
          roleId: 40437,
        },
        component: () =>
          import(
            /* webpackChunkName: "account" */ "../views/system/account.vue"
          ),
      },
    ],
  },
];

routeArr.push({
  path: "/*",
  name: "404*",
  component: () => import(/* webpackChunkName: "404" */ "../views/nofound.vue"),
});
export { allRoute, treeRootData, routeArr };
