import * as request from "@/plugin/request";
import * as common from "@/plugin/common";
import Vue from "vue";
import { formatTimeHms, initDateAdd } from "../plugin/common";
import { number } from "echarts";

/**
 * [calculateBasicsPayee 计算缴费金额第一年基础值]
 * @param  {[type]} contract_fee_setting               [contract_fee_setting]
 * @param  {[type]} fees_list               [费用数组]
 * @param  {[type]} years                   [年份月份数据]
 * @param  {[type]} start                   [合同开始时间]
 * @param  {[type]} start                   [合同结束时间]
 * @param  {[type]} time                   [天数]
 * @return {[type]}                      [description]
 */
const calculateBasicsPayee = (
  contract_fee_setting,
  fees_list,
  years,
  start,
  end,
  time,
  copyFilist
) => {
  let total = 0;

  for (let j = 0; j < contract_fee_setting.length; j++) {
    contract_fee_setting[j].forEach((item, index) => {
      if (index > 0) {
        if (!item.pay_price) {
          item.pay_price = 0;
        }
        total = (total * 100 + item.pay_price * 100) / 100;
        let obj = calculatePrice(
          item,
          years,
          start,
          end,
          time,
          copyFilist,
          index
        );

        if (j == 0) {
          fees_list.push({
            id: item.id.toString(),
            is_rent: item.is_rent,
            name: item.contract_fee_name,
            contract_rent_total: obj.obj.contract_rent_total,
            contract_rentInfo: obj.obj.contract_rentInfo,
            original_pay_price: Number(item.pay_price).toFixed(2).toString(),
            pay_price: Number(item.pay_price).toFixed(2).toString(),
            total: obj.total,
            change: 1,
            contract_increasing: 0
          });
        } else {
          let contract_rentInfo = [];
          if (fees_list[parseInt(index) - 1]) {
            fees_list[parseInt(index) - 1].contract_rentInfo.forEach(
              (pay, k) => {
                if (!copyFilist[index - 1]) {
                  pay.pay_price = Number(parseFloat((obj.obj.contract_rentInfo[k].pay_price * 100 + pay.pay_price * 100) / 100).toFixed(2));
                }
                contract_rentInfo.push(pay);
              }
            );
          }
          fees_list[parseInt(index) - 1] = {
            id: item.id.toString(),
            is_rent: item.is_rent,
            contract_rent_total: obj.obj.contract_rent_total,
            contract_rentInfo: contract_rentInfo,
            name: item.contract_fee_name,
            original_pay_price: (
              Number(fees_list[parseInt(index) - 1].original_pay_price) +
              Number(item.pay_price)
            )
              .toFixed(2)
              .toString(),
            pay_price: (
              Number(fees_list[parseInt(index) - 1].pay_price) +
              Number(item.pay_price)
            )
              .toFixed(2)
              .toString(),
            total: obj.total,
            change: 1,
            contract_increasing: 0
          };
        }
      }
    });
  }
  fees_list.forEach((item) => {
    const initialValue = 0;
    const sumWithInitial = item.contract_rentInfo.reduce((previousValue, currentValue) => {
      return previousValue + Number(currentValue.pay_price)
    }, initialValue);
    item.total = sumWithInitial;
  });
  return {
    total: Number(parseFloat(total).toFixed(2)),
    fees_list,
  };
};

/**
 * [endBasicsPayee 计算缴费金额最后一年基础值]
 * @param  {[type]} contract_fee_setting               [contract_fee_setting]
 * @param  {[type]} fees_list               [费用数组]
 * @param  {[type]} years                   [年份月份数据]
 * @param  {[type]} start                   [合同开始时间]
 * @param  {[type]} start                   [合同结束时间]
 * @param  {[type]} time                   [天数]
 * @return {[type]}                      [description]
 */
const endBasicsPayee = (contract_fee_setting, fees_list, years, start, end, time, copyFilist, yearIndex) => {
  let total = 0;
  for (let j = 0; j < contract_fee_setting.length; j++) {
    contract_fee_setting[j].forEach((item, index) => {
      if (index > 0) {
        item = calculate(item, copyFilist[Number(index) - 1] ? JSON.parse(JSON.stringify(copyFilist[Number(index) - 1])) : null, j);
        if (!item.pay_price) {
          item.pay_price = 0;
        }
        total = (total * 100 + item.pay_price * 100) / 100;
        let obj = calculateEndPrice(item, years, start, end, time, copyFilist, index, yearIndex);
        if (j == 0) {
          fees_list.push({
            id: item.id.toString(),
            is_rent: item.is_rent,
            name: item.contract_fee_name,
            contract_rent_total: obj.obj.contract_rent_total,
            contract_rentInfo: obj.obj.contract_rentInfo,
            original_pay_price: Number(item.pay_price).toFixed(2).toString(),
            pay_price: Number(item.pay_price).toFixed(2).toString(),
            total: obj.total,
            change: copyFilist.length > 0 ? copyFilist[Number(index) - 1].change : 1,
            contract_increasing: copyFilist.length > 0 ? copyFilist[Number(index) - 1].contract_increasing : 0
          });
        } else {
          let contract_rentInfo = [];
          if (fees_list[parseInt(index) - 1]) {
            fees_list[parseInt(index) - 1].contract_rentInfo.forEach(
              (pay, k) => {
                // pay.pay_price = (obj.obj.contract_rentInfo[k].pay_price * 100 + pay.pay_price * 100) / 100;
                pay.pay_price = Number(parseFloat((obj.obj.contract_rentInfo[k].pay_price * 100 + pay.pay_price * 100) / 100).toFixed(2));
                contract_rentInfo.push(pay);
              }
            );
          }
          fees_list[parseInt(index) - 1] = {
            id: item.id.toString(),
            is_rent: item.is_rent,
            contract_rent_total: obj.obj.contract_rent_total,
            contract_rentInfo: contract_rentInfo,
            name: item.contract_fee_name,
            original_pay_price: (Number(fees_list[parseInt(index) - 1].original_pay_price) + Number(item.pay_price)).toFixed(2).toString(),
            pay_price: (Number(fees_list[parseInt(index) - 1].pay_price) + Number(item.pay_price)).toFixed(2).toString(),
            total: obj.total,
            change: copyFilist.length > 0 ? copyFilist[Number(index) - 1].change : 1,
            contract_increasing: copyFilist.length > 0 ? copyFilist[Number(index) - 1].contract_increasing : 0
          };
        }
      }
    });
  }
  fees_list.forEach((item) => {
    const initialValue = 0;
    const sumWithInitial = item.contract_rentInfo.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.pay_price),
      initialValue
    );
    item.total = sumWithInitial;
  });
  if (copyFilist && copyFilist.length > 0) {
    copyFilist.forEach((item, index) => {
      fees_list[index].contract_rent_total = item.contract_rent_total;
      fees_list[index].contract_rentInfo = item.contract_rentInfo;
      fees_list[index].contract_rentInfo.forEach((ite) => {
        ite.pay_price = fees_list[index].total / item.contract_rent_total;
      });
    });
  }
  return {
    total: Number(parseFloat(total).toFixed(2)),
    fees_list,
  };
};

/**
 * [calculatePrice 对于租金给予默认值]
 * @param  {[type]} item                [description]
 * @param  {[type]} years               [description]
 * @param  {[type]} start               [description]
 * @param  {[type]} end                 [description]
 * @param  {[type]} time                [description]
 * @return {[type]}       [description]
 */
const calculatePrice = (
  item,
  years,
  start,
  end,
  time,
  copyFilist,
  feeTypeIndex
) => {
  let obj = {
    contract_rent_total: 1,
    contract_rentInfo: [],
  };
  let total = 0;

  if (years.year > 1) {
    let is_derate = false;
    obj.contract_rent_total = 1;
    if (copyFilist[feeTypeIndex - 1]) {
      obj.contract_rent_total =
        copyFilist[feeTypeIndex - 1].contract_rent_total;
      obj.contract_rentInfo = copyFilist[feeTypeIndex - 1].contract_rentInfo;
    } else {
      obj.contract_rentInfo.push({
        pay_time: start,
        pay_end_time: common.formatTimeHms(new Date(common.initDateAdd(start, 12)).getTime() - 24 * 60 * 60 * 1000),
        pay_price: Number(parseFloat(item.pay_price * 12).toFixed(2)),
        old_pay: '0',
        contract_fee_name: "基础费用",
        is_derate: is_derate,
        derate_start_time: '',
        derate_end_time: '',
        derate_pay: '',
        bill_status: 0,
      });
    }
    total += Number(parseFloat(item.pay_price * 12).toFixed(2));
  } else if (years.year == 1) {
    // 只有一年的
    let is_derate = false;
    // obj.contract_rent_total = time.months;
    obj.contract_rent_total = 1;
    if (time.months == 1) {
      let date = start.split("-");
      let date1 = end.split("-");
      let num = parseInt(date1[2]) - parseInt(date[2]) + 1;
      let totalDay = common.getMonthMaxMin(
        parseInt(date[0]),
        parseInt(date[1])
      );
      obj.contract_rentInfo.push({
        pay_time: start,
        pay_end_time: end,
        // pay_price: Number(
        //   parseFloat((item.pay_price / totalDay) * num).toFixed(2)
        // ),
        pay_price: Number(item.pay_price),
        old_pay: '0',
        contract_fee_name: "基础费用",
        is_derate: is_derate,
        bill_status: 0,
        derate_start_time: '',
        derate_end_time: '',
        derate_pay: ''
      });
      total += Number(parseFloat(item.pay_price).toFixed(2));
    } else {

      for (let i = 0; i < time.months; i++) {
        // if (time.frontDay && i == 0) {
        //   let date = start.split("-");
        //   let totalDay = common.getMonthMaxMin(parseInt(date[0]), parseInt(date[1]));
        //   total += Number(parseFloat((item.pay_price / totalDay) * time.frontDay).toFixed(2));
        // } else if (i == time.months - 1 && time.laterDay) {
        //   // 这里判断没有来年过后，计算最后的金额
        //   let date = end.split("-");
        //   let totalDay = common.getMonthMaxMin(
        //     parseInt(date[0]),
        //     parseInt(date[1])
        //   );
        //   total += Number(
        //     parseFloat(
        //       (item.pay_price / totalDay) * (totalDay - time.laterDay)
        //     ).toFixed(2)
        //   );
        // } else {
        //   let date = start.split("-");
        //   total += Number(item.pay_price);
        // }
        total += Number(item.pay_price);
      }
      for (let i = 0; i < obj.contract_rent_total; i++) {
        obj.contract_rentInfo.push({
          pay_time: start,
          pay_end_time: end,
          pay_price: total,
          old_pay: '0',
          contract_fee_name: "基础费用",
          is_derate: is_derate,
          derate_start_time: '',
          derate_end_time: '',
          derate_pay: '',
          bill_status: 0,
        });
      }
    }
  }

  return {
    obj,
    total,
  };
};

/**
 * [calculateEndPrice 对于租金给予默认值]
 * @param  {[type]} item                [description]
 * @param  {[type]} years               [description]
 * @param  {[type]} start               [description]
 * @param  {[type]} end                 [description]
 * @param  {[type]} time                [description]
 * @return {[type]}       [description]
 */
const calculateEndPrice = (
  item,
  years,
  start,
  end,
  time,
  copyFilist,
  feeTypeIndex,
  yearIndex
) => {
  let obj = {
    contract_rent_total: "",
    contract_rentInfo: [],
  };
  obj.contract_rent_total = 1;
  let date = end.split("-");
  let startT = start.split("-");
  let total = 0;
  let ad = common.initDateAdd(new Date(start).getTime(), (years.year - 1) * 12);
  let oney = common.calculateMonth(ad, end)
  let totalDay = common.getMonthMaxMin(
    parseInt(date[0]),
    parseInt(date[1])
  );
  if (oney.months == 1) {
    let da = oney.frontDay - oney.laterDay
    if (da <= 0) {
      da = totalDay - (oney.laterDay + oney.frontDay)
    }
    total = Number(((item.pay_price / totalDay) * (da)).toFixed(2))
  }
  if (oney.months > 1) {
    for (let i = 0; i < oney.months; i++) {
      // if (i == 0) {
      //   //第一个月
      //   let date = ad.split("-")
      //   let totalD = common.getMonthMaxMin(
      //     parseInt(date[0]),
      //     parseInt(date[1])
      //   );
      //   total += Number(((item.pay_price / totalD) * (oney.frontDay == 0 ? totalD : oney.frontDay)))
      // } else if (i == oney.months - 1) {
      //   // 最后一个月
      //   total += Number(((item.pay_price / totalDay) * (totalDay - oney.laterDay)))
      // } else {
      //   total += item.pay_price * 1
      // }
      total += item.pay_price * 1
    }
  }
  for (let i = 0; i < obj.contract_rent_total; i++) {
    let is_derate = false;
    obj.contract_rentInfo.push({
      pay_time: common.initDateAdd(start, yearIndex * 12),
      pay_end_time: end,
      pay_price: Number(total.toFixed(2)),
      old_pay: '0',
      contract_fee_name: "基础费用",
      is_derate: is_derate,
      bill_status: 0,
    });
  }

  return {
    obj,
    total,
  };
};

/**
 * [yearRound 整年]
 * @param  {[type]} contract_fee_setting               [description]
 * @param  {[type]} fees_list                          [description]
 * @param  {[type]} start                              [description]
 * @param  {[type]} i                                  [description]
 * @return {[type]}                      [description]
 */
const yearRound = (contract_fee_setting, fees_list, start, i, copyFilist) => {
  //contract_fee_setting 多少个店铺数据
  let total = 0;
  for (let j = 0; j < contract_fee_setting.length; j++) {
    contract_fee_setting[j].forEach((item, index) => {
      if (index > 0) {
        item = calculate(item, copyFilist[Number(index) - 1] ? JSON.parse(JSON.stringify(copyFilist[Number(index) - 1]), j) : null, j);

        if (!item.pay_price) {
          item.pay_price = 0;
        }
        total = (total * 100 + item.pay_price * 100) / 100;
        let obj = {
          contract_rent_total: "",
          contract_rentInfo: [],
          total: 0,
        };
        // obj.contract_rent_total = 12;
        obj.contract_rent_total = 1;
        let feeTypeIndex = index;
        for (let k = 1; k < 2; k++) {
          let is_derate = false;
          if (feeTypeIndex > 0 && copyFilist && copyFilist.length > 0 && copyFilist[feeTypeIndex - 1].contract_rentInfo) {
            is_derate = copyFilist[feeTypeIndex - 1].contract_rentInfo[k - 1]?.is_derate || false;
          }
          let date = start.split("-");
          obj.contract_rentInfo.push({
            pay_time: common.initDateAdd(start, i * 12),
            pay_end_time: common.formatTimeHms(new Date(common.initDateAdd(start, (i + 1) * 12)).getTime() - 24 * 60 * 60 * 1000),
            pay_price: Number((item.pay_price * 12).toFixed(2)),
            old_pay: '0',
            contract_fee_name: "基础费用",
            is_derate: is_derate,
            bill_status: 0,
          });
          obj.total += Number((item.pay_price * 12).toFixed(2));
        }
        if (j == 0) {
          fees_list.push({
            id: item.id.toString(),
            is_rent: item.is_rent,
            name: item.contract_fee_name,
            contract_rent_total: obj.contract_rent_total,
            contract_rentInfo: obj.contract_rentInfo,
            original_pay_price: Number(item.pay_price).toFixed(2).toString(),
            pay_price: Number(item.pay_price).toFixed(2).toString(),
            total: obj.total,
            change: copyFilist.length > 0 ? copyFilist[Number(index) - 1].change : 1,
            contract_increasing: copyFilist.length > 0 ? copyFilist[Number(index) - 1].contract_increasing : 0
          });
        } else {
          let contract_rentInfo = [];
          if (fees_list[parseInt(index) - 1]) {
            fees_list[parseInt(index) - 1].contract_rentInfo.forEach((pay, k) => {
              // pay.pay_price = (obj.contract_rentInfo[k].pay_price * 100 + pay.pay_price * 100) / 100;
              pay.pay_price = Number(parseFloat((obj.contract_rentInfo[k].pay_price * 100 + pay.pay_price * 100) / 100).toFixed(2));
              contract_rentInfo.push(pay);
            }
            );
          }
          fees_list[parseInt(index) - 1] = {
            id: item.id.toString(),
            is_rent: item.is_rent,
            contract_rent_total: obj.contract_rent_total,
            contract_rentInfo: contract_rentInfo,
            name: item.contract_fee_name,
            original_pay_price: (Number(fees_list[parseInt(index) - 1].original_pay_price) + Number(item.pay_price)).toFixed(2),
            pay_price: (Number(fees_list[parseInt(index) - 1].pay_price) + Number(item.pay_price)).toFixed(2),
            total: obj.total,
            change: copyFilist.length > 0 ? copyFilist[Number(index) - 1].change : 1,
            contract_increasing: copyFilist.length > 0 ? copyFilist[Number(index) - 1].contract_increasing : 0
          };
        }
      }
    });
  }

  fees_list.forEach((item) => {
    const initialValue = 0;
    const sumWithInitial = item.contract_rentInfo.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.pay_price),
      initialValue
    );
    item.total = sumWithInitial;
  });
  if (copyFilist && copyFilist.length > 0) {
    copyFilist.forEach((item, index) => {
      fees_list[index].contract_rent_total = item.contract_rent_total;
      fees_list[index].contract_rentInfo = item.contract_rentInfo;
      fees_list[index].contract_rentInfo.forEach((ite) => {
        ite.pay_price = fees_list[index].total / item.contract_rent_total;
      });
    });
  }
  return {
    fees_list,
    total: Number(parseFloat(total).toFixed(2)),
  };
};

/**
 * [calculate 计算递增]
 * @param  {[type]} item               [商铺缴费项]
 * @param  {[type]} filistItem            [缴费项数据]
 * @return {[type]}         [description]
 */
const calculate = (item, filistItem, shopIndex) => {
  console.log(item, filistItem?.contract_increasing)
  // let total = 0;
  if (filistItem && Number(filistItem.contract_increasing)) {
    // 有递增率
    if (!item.original_pay_price) {
      item.original_pay_price = item.pay_price;
    }
    // 判断是固定比还是百分比
    if (filistItem.change == 1) {
      item.pay_price = (item.original_pay_price * (1 + Number(filistItem.contract_increasing) / 100)).toFixed(2).toString();
    } else {
      /*
      * 以前这里的递增金额是加到每个店铺上的，现在需求是在所有店铺金额至上，
      * 处理：把固定金额加在了第一个店铺上，因为其它地方用到了pay_price这个字段 有了关连 所以才加到第一个店铺上
      * */
      if (shopIndex == 0) {
        item.pay_price = (Number(item.original_pay_price) + Number(filistItem.contract_increasing)).toFixed(2).toString();
      } else {
        item.pay_price = Number(item.pay_price).toFixed(2).toString();
      }

    }
    item.original_pay_price = item.pay_price;
    // total += Number(item.pay_price);
  } else {
    // 无递增率
    if (!item.original_pay_price) {
      item.original_pay_price = item.pay_price;
    }
    item.pay_price = (item.original_pay_price * 1).toFixed(2).toString();
    item.original_pay_price = item.pay_price;
    // total += Number(item.pay_price);
  }
  return item;
  // if (ids.length === 0) {
  //   feesLit.forEach((item, i) => {
  //     if (i != 0) {
  //       if (!item.original_pay_price) {
  //         item.original_pay_price = item.pay_price;
  //       }
  //       item.pay_price = (item.original_pay_price * 1).toFixed(2).toString();
  //       item.original_pay_price = item.pay_price;
  //       total += Number(item.pay_price);
  //     }
  //   });
  // } else {
  //   feesLit.forEach((item, i) => {
  //     if (i != 0) {
  //       if (
  //         ids.indexOf(item.id.toString()) != -1 ||
  //         ids.indexOf(item.parent_id.toString()) != -1
  //       ) {
  //         if (!item.original_pay_price) {
  //           item.original_pay_price = item.pay_price;
  //         }
  //         // 判断是固定比还是百分比
  //         if (change == 1) {
  //           item.pay_price = (item.original_pay_price * (1 + contract_increasing / 100)).toFixed(2).toString();
  //         } else {
  //           item.pay_price = (Number(item.original_pay_price) + Number(contract_increasing)).toFixed(2).toString();
  //         }

  //         item.original_pay_price = item.pay_price;
  //       }
  //       total += Number(item.pay_price);
  //     }
  //   });
  // }
  // return {
  //   total,
  //   feesLit,
  // };
};

/**
 * [contract 合同store]
 */
export const contract = {
  namespaced: true,
  state: {
    contractTemplate: [],
    merchantsList: [],
    reliefType: [],
    contractChargeSetList: [],
    iDNumber: "",
    dateedShop: [],
    iscomplete: false,
    intentionshop: [],
    tableData: [],
    rent_holiday_date: [],
    id: "",
    type: "",
    isLeaseStatus: false,
    form: {
      contract_type: 10,
      property_tpl_id: "",
      contract_no: "",
      contract_tplName: "",
      rent_free_price: "",
      remark: "",
      contract_tpl: "",
      monthTotal: [],
      contract_fee_setting: [],
      dcList: [],
      month_price: 0,
      contract_increasing: [],
      rent_holiday: "",
      total_price: 0,
      contract_start: "",
      contract_end: "",
      merchants_stall: [],
      contract_fee_type: [],
      contract_charge_name: "",
      lease_status: false,
      purpose_id: [],
    },
    rules: {
      property_tpl_id: [
        { required: true, message: "请选择合同模板", trigger: "change" },
      ],
      contract_type: [
        { required: true, message: "请选择合同签订方式", trigger: "change" },
      ],
      contract_no: [
        { required: true, message: "请输入合同编号", trigger: "blur" },
      ],
      merchants_id: [
        { required: true, message: "请选择商户", trigger: "change" },
      ],
      contract_start: [
        { required: true, message: "请选择合同开始时间", trigger: "change" },
      ],
      contract_end: [
        { required: true, message: "请选择合同结束时间", trigger: "change" },
      ],
    },
    tempContent: "",
    earnestMoney: [],
  },
  mutations: {
    /**
     * [setEarnestMoney 设置意向金列表]
     * @param {[type]} state  [state]
     * @param {[type]} list   [数据]
     */
    setEarnestMoney (state, list) {
      state.earnestMoney = list;
    },
    /**
     * [setIsLeaseStatus 设置是否显示合同重复出租]
     * @param {[type]} state  [state]
     * @param {[type]} type   [type]
     */
    setIsLeaseStatus (state, type) {
      state.isLeaseStatus = type;
    },
    /**
     * [setIntentionMoneyShop 意向金获取的商铺进行数据组装]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setIntentionMoneyShop (state, data) {
      const temp = [];
      for (let i in data) {
        const chargeList = [
          {
            merchants_stall_id: data[i].merchants_stall_id,
            total_pay_price: 0,
            pay_price: 0,
            month_pay_price: data[i].merchants_stall_price,
            merchants_stall_price: data[i].merchants_stall_price,
          },
        ];
        let [tempArr] = state.contractTemplate.filter(
          (item) => item.property_setting_id == state.form.property_tpl_id
        );

        // 进行收费项组装
        state.contractChargeSetList.forEach((item) => {
          if (tempArr.values.chargingItemsId.indexOf(item.id) != -1) {
            // 月缴费
            if (item.rent_type == 10) {
              chargeList.push({ ...item });
            }
          }
        });
        if (chargeList.length > 1) {
          chargeList[1].pay_price = data[i].merchants_stall_price;
        }
        temp.push(chargeList);
      }
      if (state.form.contract_fee_setting[0].merchants_stall_id) {
        state.form.contract_fee_setting =
          state.form.contract_fee_setting.concat(temp);
      } else {
        state.form.contract_fee_setting = temp;
      }
    },
    setChangeContract (state, id) {
      state.form.property_tpl_id = id;
      const chargeList = [
        {
          merchants_stall_id: "",
          total_pay_price: 0,
          pay_price: 0,
          month_pay_price: 0,
        },
      ];
      // 固定收费项目数组
      const fixationList = [];
      const monthTotal = [];
      let [tempArr] = state.contractTemplate.filter(
        (item) => item.property_setting_id == id
      );
      // 商铺租金为第一个，设置默认价格
      // let [chargeListOen] = chargeList;
      // chargeListOen.month_pay_price = this.contractChargeSetList[0].pay_price;
      // 进行收费项组装
      state.contractChargeSetList.forEach((item) => {
        if (tempArr.values.chargingItemsId.indexOf(item.id) != -1) {
          // 月缴费
          if (item.rent_type == 10) {
            chargeList.push({
              ...item,
            });
            monthTotal.push({
              ...item,
              totalMonery: "",
            });
          }
          // 固定缴费
          if (item.rent_type == 20) {
            fixationList.push({
              ...item,
              pay_price: item.pay_price ? item.pay_price : "",
              paid_amount: 0,
              paid_time: ''
            });
          }
        }
      });
      state.form.monthTotal = monthTotal;
      state.form.contract_fee_setting.push(chargeList);
      state.form.dcList = fixationList;
    },
    /**
     * [intentionshop 意向商铺]
     * @param  {[type]} state               [state]
     * @param  {[type]} data                [data]
     */
    setIntentionshop (state, data) {
      state.intentionshop = data;
    },
    /**
     * [initData 初始化数据]
     * @param  {[type]} state               [state]
     */
    initData (state) {
      state.form = {
        contract_type: 10,
        property_tpl_id: "",
        contract_no: "",
        contract_tplName: "",
        contract_tpl: "",
        monthTotal: [],
        contract_fee_setting: [],
        dcList: [],
        month_price: 0,
        contract_increasing: [],
        rent_holiday: "",
        total_price: 0,
        contract_start: "",
        contract_end: "",
        contract_charge_name: "",
        merchants_stall: [],
        lease_status: false,
        purpose_id: [],
      };
      state.iscomplete = false;
    },
    /**
     * [setIscomplete 是否可以提交]
     * @param {[type]} state  [state]
     * @param {[type]} type   [type]
     */
    setIscomplete (state, type) {
      state.iscomplete = type;
    },
    /**
     * [setContractTemplate 设置合同模板列表]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setContractTemplate (state, data) {
      state.contractTemplate = data;
    },
    /**
     * [setContractFile 设置上传的合同文件]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setContractFile (state, data) {
      state.form.contract_tplName = data.fileInfo.fileName;
      state.form.contract_tpl =
        process.env.VUE_APP_IMG_URL + data.fileInfo.hostUrl;
    },
    /**
     * [setMerchantsList 设置商户列表]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setMerchantsList (state, data) {
      state.merchantsList = data;
    },
    /**
     * [setReliefType 设置减免项]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setReliefType (state, data) {
      state.reliefType = data;
    },
    /**
     * [setContractChargeSetList 设置合同收费项目]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setContractChargeSetList (state, data) {
      state.contractChargeSetList = data;
    },
    /**
     * [setFormData 更新设置form的数据]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setFormData (state, data) {
      state.form[data.name] = data.data;
    },
    /**
     * [setChangeMerchants 选择商户获取证件号码]
     * @param {[type]} state  [state]
     * @param {[type]} id     [id]
     */
    setChangeMerchants (state, id) {
      const [tempArr] = state.merchantsList.filter(
        (item) => item.merchants_id == id
      );
      state.iDNumber = tempArr.merchants_card_id;
    },
    /**
     * [setDateedShop 设置商铺列表]
     * @param {[type]} state  [description]
     * @param {[type]} data   [description]
     */
    setDateedShop (state, data) {
      state.dateedShop = data;
    },
    /**
     * [calculateMonthlyAmount 计算月金额]
     * @param  {[type]} state               [state]
     */
    calculateMonthlyAmount (state) {
      state.form.monthTotal.forEach((item) => {
        item.month_pay_price = 0;
      });
      let total = 0;
      const contract_fee_setting = state.form.contract_fee_setting;
      for (let i = 0; i < contract_fee_setting.length; i++) {
        for (let j = 0; j < contract_fee_setting[i].length; j++) {
          if (j != 0 && contract_fee_setting[i][j].pay_price) {
            if (j == 1) {
              contract_fee_setting[i][0].pay_price = contract_fee_setting[i][j].pay_price;
            }
            total = (total * 100 + contract_fee_setting[i][j].pay_price * 100) / 100;
            state.form.monthTotal[parseInt(j) - 1].month_pay_price =
              (state.form.monthTotal[parseInt(j) - 1].month_pay_price * 100 + contract_fee_setting[i][j].pay_price * 100) / 100 || 0;
          }
        }
      }
      state.form.month_price = total;
    },
    /**
     * [initProgressiveIncrease 初始化递增基础结构数据]
     * @param  {[type]} state               [state]
     */
    initProgressiveIncrease (state, years) {
      let tempArr = [];
      for (let i = 0; i < years; i++) {
        tempArr.push({
          fees_list: [],
          total_pay_price: 0,
          month_pay_price: 0,
          active_name: "0",
        });
      }
      state.form.contract_increasing = JSON.parse(JSON.stringify(tempArr));
    },
    /**
     * [calculateIncrease 计算递增]
     * @param  {[type]} state               [state]
     */
    calculateIncrease (state) {
      let time = common.calculateMonth(
        state.form.contract_start,
        state.form.contract_end
      );
      let years = common.calculateYear(
        state.form.contract_start,
        state.form.contract_end
      );

      let contract_increasing = state.form.contract_increasing;
      let contract_fee_setting = state.form.contract_fee_setting;
      let monthTotal = state.form.monthTotal;
      let temp_contract_fee_setting = JSON.parse(
        JSON.stringify(contract_fee_setting)
      );
      let oneYearVal = null;
      // 清空总金额
      state.form.total_price = 0;
      let totalPrice = 0;
      // 清空总费用总的值
      monthTotal.forEach((item) => {
        item.total_pay_price = 0;
      });

      for (let i = 0; i < contract_increasing.length; i++) {
        let copyFilist = contract_increasing[i].fees_list;
        let fees_list = [];
        let total = 0;
        let tempVal = 0;
        // 计算第一年的时候的值，后面年份的基础值根据第一年的值来赋值

        if (i == 0) {
          let temp = calculateBasicsPayee(
            contract_fee_setting,
            fees_list,
            years,
            state.form.contract_start,
            state.form.contract_end,
            time,
            copyFilist
          );
          oneYearVal = temp;
          fees_list = temp.fees_list;
          total = temp.total;
        } else {
          if (years.year > 2 && i != years.year - 1) {
            // 说明后面还有年份
            // 判断递增率是否有值，有值就开始重新计算费用，无值直接复制第一年的基础值
            // Array.isArray(temp_contract_fee_setting) &&
            //   temp_contract_fee_setting.forEach((ite) => {
            //     calculate(
            //       ite,
            //       contract_increasing[i].change,
            //       contract_increasing[i].type,
            //       contract_increasing[i].contract_increasing
            //     );
            //   });

            let temp = yearRound(
              temp_contract_fee_setting,
              fees_list,
              state.form.contract_start,
              i,
              copyFilist
            );
            fees_list = temp.fees_list;
            total = temp.total;
          } else {
            // 最后一年了
            // 判断递增率是否有值，有值就开始重新计算费用，无值直接复制第一年的基础值
            // if (contract_increasing[i].contract_increasing != '0' && contract_increasing[i].contract_increasing) {
            //   temp_contract_fee_setting = JSON.parse(JSON.stringify(temp_contract_fee_setting));
            //   Array.isArray(temp_contract_fee_setting) && temp_contract_fee_setting.forEach(ite=>{
            //     calculate(ite, contract_increasing[i].type, contract_increasing[i].contract_increasing);
            //   })
            //   let temp = endBasicsPayee(temp_contract_fee_setting, fees_list, years, state.form.contract_start, state.form.contract_end, time,copyFilist,i);
            //   fees_list = temp.fees_list;
            //   total = temp.total;
            // } else {
            //   let temp = endBasicsPayee(contract_fee_setting, fees_list, years, state.form.contract_start, state.form.contract_end, time,copyFilist,i);
            //   fees_list = temp.fees_list;
            //   total = temp.total;
            // }
            // Array.isArray(temp_contract_fee_setting) &&
            //   temp_contract_fee_setting.forEach((ite) => {
            //     calculate(
            //       ite,
            //       contract_increasing[i].change,
            //       contract_increasing[i].type,
            //       contract_increasing[i].contract_increasing
            //     );
            //   });

            let temp = endBasicsPayee(temp_contract_fee_setting, fees_list, years, state.form.contract_start, state.form.contract_end, time, copyFilist, i);
            fees_list = temp.fees_list;
            total = temp.total;
          }
        }
        fees_list.forEach((item, j) => {
          let total_pay_price = 0;
          item.contract_rentInfo.forEach((list) => {
            tempVal += Number(list.pay_price);
            total_pay_price += Number(list.pay_price);
          });
          monthTotal[j].total_pay_price = Number(
            parseFloat((total_pay_price * 100 + monthTotal[j].total_pay_price * 100) / 100).toFixed(2)
          );
        });
        totalPrice += Number(tempVal);
        Vue.set(state.form.contract_increasing, i, {
          ...state.form.contract_increasing[i],
          month_pay_price: Number(total).toFixed(2).toString(),
          total_pay_price: Number(tempVal).toFixed(2).toString(),
          fees_list: JSON.parse(JSON.stringify(fees_list)),
        });
      }

      state.form.total_price = Number(totalPrice).toFixed(2);
    },
    /**
     * [calculateRentInfo 计算基础费用缴费周期对应的金额]
     * @param  {[type]} state               [state]
     * @param  {[type]} type               [如果是页面是bulr 就是true]
     */
    calculateRentInfo (state, type, a) {
      // let contract_increasing = state.form.contract_increasing;
      // for (let i in contract_increasing) {
      //   let fees_list = contract_increasing[i].fees_list;
      //   for (let j in fees_list) {
      //     let contractRentInfo = [];
      //     if (fees_list[j].contract_rent_total) {
      //       let remainder = fees_list[j][fees_list[j].name] % fees_list[j].contract_rent_total;
      //       let average = parseInt(fees_list[j][fees_list[j].name] / fees_list[j].contract_rent_total);
      //       for (let k = 0; k < parseInt(fees_list[j].contract_rent_total); k++) {
      //         let tempObj = null;
      //         let val = 0;
      //         if (fees_list[j].contract_rentInfo[k]) {
      //           tempObj = fees_list[j].contract_rentInfo[k];
      //         }
      //         if (k == parseInt(fees_list[j].contract_rent_total) - 1) {
      //           val = average + remainder;
      //         } else {
      //           val = average;
      //         }
      //         if (type) {
      //           contractRentInfo.push({
      //             pay_time: tempObj ? tempObj.pay_time : '',
      //             old_pay: tempObj ? tempObj.old_pay : '0',
      //             pay_price: parseFloat(val).toFixed(2),
      //             contract_fee_name: fees_list[i].name,
      //             is_derate: false,
      //             bill_status: 0
      //           });
      //         } else {
      //           contractRentInfo.push({
      //             pay_time: tempObj ? tempObj.pay_time : '',
      //             old_pay: tempObj ? tempObj.old_pay : '0',
      //             pay_price: tempObj ? parseFloat(tempObj.pay_price).toFixed(2) : parseFloat(val).toFixed(2),
      //             contract_fee_name: '基础费用',
      //             is_derate: false,
      //             bill_status: 0
      //           });
      //         }
      //       }
      //       fees_list[j].contract_rentInfo = contractRentInfo;
      //     }
      //   }
      // }
    },
    /**
     * [setMerchantsStall 设置商铺id]
     * @param {[type]} state  [state]
     * @param {[type]} data  [data]
     */
    setMerchantsStall (state, data) {
      state.form.merchants_stall = data;
    },
    /**
     * [setFormDetail 设置合同详情]
     * @param {[type]} state   [state]
     * @param {[type]} detail  [detail]
     */
    setFormDetail (state, detail) {
      state.form = detail;
    },
    /**
     * [setTableData 设置数据维护/复核商户信息]
     * @param {[type]} state  [state]
     * @param {[type]} data   [data]
     */
    setTableData (state, data) {
      state.tableData = [
        {
          merchants_stall_name: data.merchants_stall_name,
          merchants_phone: data.merchants_phone,
          contract_card_no: data.contract_card_no,
          contract_no: data.contract_no,
          contract_start: data.contract_start,
          contract_end: data.contract_end,
        },
      ];
    },
    setContent (state, e) {
      let data = state.contractTemplate.find(
        (item) => item.property_setting_id == e
      );
      if (data) {
        state.tempContent = data.values.content;
      }
    },
  },
  actions: {
    /**
     * [getContract 获取合同模板]
     * @param  {[type]} commit               [commit]
     */
    getContract ({ commit }, data) {
      return request.HttpPost("/setting/list", {
        pageNum: 1,
        pageSize: 100000,
        key: "propertyTplSetting",
      }).then((res) => {
        if (res.data && res.data.list) {
          res.data.list.forEach((item) => {
            if (item.child_value) {
              item.child_value.values = JSON.parse(item.child_value.values);
            }
            item.values = JSON.parse(item.values);
          });
        }
        commit("setContractTemplate", res.data.list);
        if (data) {
          commit("setContent", data);
        }
        return res;
      });
    },
    /**
     * [uploadFiles 上传合同文件]
     * @param  {[type]} commit               [commit]
     * @return {[type]}        [description]
     */
    uploadFiles ({ commit }, formData) {
      request.HttpPost("/system/upload/upFile", formData).then((res) => {
        commit("setContractFile", res.data);
      });
    },
    /**
     * [getMerchantsList 获取商户列表]
     * @param  {[type]} commit               [description]
     * @return {[type]}        [description]
     */
    getMerchantsList ({ commit }) {
      request
        .HttpPost("/merchants/list", {
          pageNum: 1,
          pageSize: 10000,
        })
        .then((res) => {
          commit("setMerchantsList", res.data.list || []);
        });
    },
    /**
     * [getContractChargeSetList 获取合同收费项目]
     * @param  {[type]} commit               [description]
     * @return {[type]}        [description]
     */
    getContractChargeSetList ({ commit }) {
      return request
        .HttpPost("/contract_fee_setting/listAll", {
          is_contract_vice: 20,
        })
        .then((res) => {
          if (res.data) {
            let reliefType = [];
            res.data.forEach((item) => {
              if (item.rent_type == 10) {
                reliefType.push(item);
              }
              item.month_pay_price = 0;
              item.pay_time = "";
            });
            commit("setReliefType", reliefType);
            commit("setContractChargeSetList", res.data);
            return res;
          }
        });
    },
    /**
     * [getShopList 获取商铺列表]
     * @param  {[type]} commit               [description]
     * @param  {[type]} params               [description]
     * @return {[type]}        [description]
     */
    getShopList ({ commit }, params) {
      return request
        .HttpPost("/merchants_stall/listAll", params)
        .then((res) => {
          if (res.data) {
            res.data.forEach((item) => {
              item.disabled = false;
              item.pitchVal = "";
            });
            commit("setDateedShop", res.data || []);
          }
          return res;
        })
        .catch((err) => {
          return err;
        })
        .finally((res) => {
          return res;
        });
    },
    /**
     * [getContractDetail 获取合同详情]
     * @param  {[type]} commit               [commit]
     * @param  {[type]} state                [state]
     * @param  {[type]} params               [参数]
     */
    getContractDetail ({ commit, state, dispatch }, params) {
      state.id = params.id;
      state.type = params.type || "";
      return request.HttpGet("/contract/edit", {
        id: params.id,
      }).then(async (res) => {
        // 详情的时候，证件号直接赋值上去
        state.iDNumber = res.data.contract_card_no;
        if (res.data.contract_fee_type) {
          res.data.contract_fee_type = JSON.parse(res.data.contract_fee_type);
        } else {
          res.data.contract_fee_type = [];
        }
        // 续签合同
        if (params.type == "renew") {
          let time = common.monthsBetw(
            res.data.contract_start,
            res.data.contract_end
          );
          let date = common.formatDateTime(
            common.addMonth(res.data.contract_end, time.months)
          );
          res.data.contract_start = common.formatDateTime(new Date(res.data.contract_end).getTime() + 1000 * 60 * 60 * 24);
          res.data.contract_end = common.formatDateTime(new Date(date).getTime());
          await dispatch("getShopList", {
            endTime: res.data.contract_start,
            contract_id: params.id,
          });
          if (res.data.contract_increasing) {
            res.data.contract_increasing = JSON.parse(
              res.data.contract_increasing
            );
            // // 把缴费时间初始化
            res.data.contract_increasing.forEach((item) => {
              item.contract_rent_total = "";
              item.fees_list.forEach((list) => {
                // list.contract_rentInfo = [];
                list.contract_rentInfo.forEach((dat) => {
                  dat.pay_time = common.formatDateTime(common.addMonth(dat.pay_time, time.months));
                  dat.pay_end_time = common.formatDateTime(common.addMonth(dat.pay_end_time, time.months));
                  dat.property_end_time = common.formatDateTime(common.addMonth(dat.property_end_time, time.months));
                  dat.property_time = common.formatDateTime(common.addMonth(dat.property_time, time.months));
                })
              })
            });
          }
          if (res.data.monthTotal) {
            res.data.monthTotal.forEach((item) => {
              if (item.id != item.parent_id) {
                item.id = item.parent_id;
              }
            });
          }
          if (res.data.dcList) {
            res.data.dcList.forEach((item) => {
              item.alreadyPayCost = item.pay_price;
              item.statuseds = false;
            });
          }
          commit("setFormDetail", {
            ...res.data,
            contract_address: "",
            contract_no: "",
          });
        } else if (params.type == "update") {
          // 新增合同
          if (res.data.monthTotal) {
            res.data.monthTotal.forEach((item) => {
              if (item.id != item.parent_id) {
                item.id = item.parent_id;
              }
            });
          }
          commit("setFormDetail", {
            ...state.form,
            contract_type: res.data.contract_type,
            property_tpl_id: res.data.property_tpl_id,
            merchants_id: res.data.merchants_id,
          });
          commit("setChangeContract", res.data.property_tpl_id);
        } else if (params.type == "edit") {
          await dispatch("getShopList", {
            endTime: res.data.contract_start,
            contract_id: params.id,
          });
          if (res.data.monthTotal) {
            res.data.monthTotal.forEach((item) => {
              if (item.id != item.parent_id) {
                item.id = item.parent_id;
              }
            });
          }
          if (res.data.contract_increasing) {
            res.data.contract_increasing = JSON.parse(res.data.contract_increasing);
          }
          commit("setFormDetail", res.data);
        }
      });
    },
    /**
     * [getDataMaintenance 批量合同列表数据维护]
     * @param  {[type]} commit               [commit]
     * @param  {[type]} id                   [id]
     */
    getDataMaintenance ({ commit, state, dispatch }, id) {
      state.id = id;
      request.HttpGet("/contract/edit", { id }).then(async (res) => {
        if (res.data.contract_increasing) {
          res.data.contract_increasing = JSON.parse(res.data.contract_increasing);
        }
        if (res.data.rent_holiday) {
          let date = common.addMonth(
            res.data.contract_start,
            res.data.rent_holiday
          );
          res.data.contract_fee_start = res.data.contract_start;
          let end = common.formatDateTime(date);
          res.data.contract_fee_end = end;
          Vue.set(state.rent_holiday_date, 0, res.data.contract_start);
          Vue.set(state.rent_holiday_date, 1, end);
        }
        if (res.data.contract_fee_type) {
          res.data.contract_fee_type = JSON.parse(res.data.contract_fee_type);
        } else {
          res.data.contract_fee_type = [];
        }
        if (res.data.monthTotal) {
          res.data.monthTotal.forEach((item) => {
            item.id = item.parent_id;
          });
        }
        if (!res.data.contract_fee_setting) {
          res.data.contract_fee_setting = [];
        }
        if (!res.data.merchants_stall) {
          res.data.merchants_stall = [];
        }
        await dispatch("getShopList", {
          endTime: res.data.contract_start,
          contract_id: id,
        });
        await commit("setTableData", res.data);
        await commit("setChangeContract", res.data.property_tpl_id);
        await commit("setFormDetail", {
          ...res.data,
          monthTotal: res.data.monthTotal
            ? res.data.monthTotal
            : state.form.monthTotal,
          dcList: res.data.dcList ? res.data.dcList : state.form.dcList,
        });
      });
    },
    /**
     * [getEarnestMoney 获取意向金列表]
     * @param  {[type]} {}               [description]
     * @return {[type]}    [description]
     */
    getEarnestMoney ({ commit }) {
      request
        .HttpGet("/purpose/list", {
          pageNum: 1,
          pageSize: 9000,
          purpose_status: 0,
        })
        .then((res) => {
          if (res.data.list) {
            res.data.list.forEach((item) => {
              item.name = `${item.purpose_no}-${item.user_name}-${item.user_phone}`
            });
          }
          commit("setEarnestMoney", res.data.list || []);
        });
    },
  },
};
