import Vue from "vue";
import Vuex from "vuex";
import { dataReport } from "./dataReport";
import { contract } from "./contract";
import { meter } from "./meter";

Vue.use(Vuex);
let store = new Vuex.Store({
  state: {
    rootRouter: [],
    roleIds: [],
    navList: [],
  },
  mutations: {
    setRoleIds(state, data) {
      let arr = [];
      if (data) {
        data.forEach((item) => {
          arr.push(item * 1);
        });
      }
      state.roleIds = arr;
    },
  },
  actions: {
    getRoleIds({ commit }, data) {
      commit("setRoleIds", data);
    },
  },
  modules: {
    dataReport,
    contract,
    meter,
  },
});
export default store;
