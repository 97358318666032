<template>
  <div class="confirm-box" v-if="show" :style="{zIndex}" @click.stop="close">
    <transition name="fade">
      <div class="box" v-if="boxshow" @click.stop>
        <div class="title">
          <span>{{ title }}</span>
          <img src="../img/gb.png" @click="btnclose" />
        </div>
        <div class="tips" v-if="type == 'confirm'">{{ tips }}</div>
        <div class="confirmslot" v-if="type !== 'confirm'">
          <slot name="content"></slot>
        </div>
        <div class="btnbox">
          <MyButton class="btn" type="primary" :disabled="disabled" @click="confirm">{{confirmText}}</MyButton>
          <!-- <div class="btn" @click.stop="confirm">确认</div> -->
          <!-- <div class="confirmbtn cancel" v-if="type == 'confirm'" @click="btnclose">
            取消
          </div>-->
          <MyButton left v-if="type == 'confirm'" type="default" @click="btnclose">{{cancelText}}</MyButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "confirm",
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  name: "confirmBox",
  data() {
    return {
      show: false,
      boxshow: false,
      tips: "",
      title: "提示",
      resolve: null,
      reject: null,
      zIndex: 2000,
      confirmText:'确认',
      cancelText:'取消'
    };
  },
  watch: {
    show(value) {
      if (!value) {
        this.$emit("close");
      }
    },
  },
  created() {
    if (this.type == 'confirm') {
      let nodelist = Array.from(document.querySelectorAll('body *'));
      let zIndexList = [];
      nodelist.forEach(item => {
        zIndexList.push(+window.getComputedStyle(item).zIndex || 0);
      })
     this.zIndex =  Math.max(...zIndexList) + 3;
    }

  },
  methods: {
    fade() {
      this.show = true;
      setTimeout(() => {
        this.boxshow = true;
      }, 50);
    },
    close(e) {
      console.log(e);
      this.show = false;
    },
    btnclose() {
      this.type == "confirm" ? this.reject("cancel") : "";
      this.show = false;
      this.boxshow = false;
    },
    async confirm() {
      if (this.type == "confirm") {
        this.resolve("confirm");
      } else {
        this.$emit("confirm");
      }
      this.show = false;
      this.boxshow = false
    },
    isconfirm() {
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000000, 0.6);
  z-index: 2020;
  padding-bottom: 25px;
  box-sizing: border-box;
  .box {
    min-width: 520px;
    min-height: 240px;
    overflow: auto;
    background: #ffffff;
       box-shadow: 1px 1px 1px .5px #dedede;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      width: 100%;
      height: 47px;
      position: relative;
      line-height: 47px;
      border-bottom: 1px solid #f5f5f5;
      text-align: center;
      img {
        width: 12px;
        height: 12px;
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
    .tips {
      margin: 50px 0;
      padding: 0 20px;
      box-sizing: border-box;
      text-align: center;
    }
    .btnbox {
      display: flex;
      align-items: center;
      margin: 15px 0;
    }
    .confirmslot {
      width: 100%;
      margin: 25px 0;
      //   height: 150px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
    }
    .cancel {
      color: #818080;
      margin-left: 15px;
    }
  }

  .fade-enter,
  .fade-leave-to {
    transform: translate(-50%, -50%) scale(0.5);
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s;
  }
  .fade-enter-to,
  .fade-leave {
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>
